<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/css/all.min.css"
  integrity="sha512-3M00D/rn8n+2ZVXBO9Hib0GKNpkm8MSUU/e2VNthDyBYxKWG+BftNYYcuEjXlyrSO637tidzMBXfE7sQm0INUg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>

<!-- Header Start-->

<header id="header" class="header" role="banner" aria-label="Site header">
  <nav class="navbar navbar-expand-lg" id="navbar-main">
    <a [routerLink]="['/']" title="Home" rel="home" class="navbar-brand"
      ><img src="../../../assets/images/cagamas-logo-new01.jpg" alt="Home" class="img-fluid d-inline-block align-top"
    /></a>
    <div class="burger-wrapper">
      <button class="navbar-toggler burger-button" onclick="mobileMenu()">
        <span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span
        ><span class="icon-bar"></span>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="CollapsingNavbar">
      <nav
        role="navigation"
        aria-labelledby="block-cagamas-main-menu-menu"
        id="block-cagamas-main-menu"
        class="block block-menu navigation menu--main"
      >
        <div class="mobile-menu-top">
          <!-- <ul class="menu">	
        <li class="nav-item menu-item" style="display: flex">	
          <a	
            href="javascript:void(0)"	
            style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a"	
            (click)="switch('en')"	
            >EN</a	
          ><span style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400">|</span	
          ><a	
            href="javascript:void(0)"	
            (click)="switch('ms')"	
            style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400"	
            >BM</a	
          >	
        </li>	
      </ul>	 -->
          <a [routerLink]="['/']" title="Home" rel="home" class="navbar-brand-mobile"
            ><img
              src="../../../assets/images/cagamas-logo-new01.jpg"
              alt="Home"
              class="img-fluid d-inline-block align-top"
          /></a>
        </div>

        <ul data-region="primary_menu" id="block-cagamas-main-menu" class="clearfix nav navbar-nav menu">
          <li class="nav-item menu-item">
            <!-- <a [routerLink]="['/eligibility-criteria']" class="nav-link"><span>Eligibility Criteria</span></a> -->
            <a [routerLink]="['/main-page']" class="nav-link"
              ><span>{{ 'ELIGIBILITY.MENU1' | translate }} </span></a
            >
          </li>
          <li class="nav-item menu-item lan-menu" style="display: flex">
            <a
              href="javascript:void(0)"
              style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a"
              (click)="switch('en')"
              >EN</a
            ><span style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400">|</span
            ><a
              href="javascript:void(0)"
              (click)="switch('ms')"
              style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400"
              >BM</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </nav>
</header>
<!-- Header End-->

<!-- Banner-->
<div class="main-wrapper">
  <div class="banner-wrapper first last">
    <div class="row no-gutters m-0">
      <div class="col-md-6 col-12">
        <div class="container">
          <div class="page-title-wrap">
            <h1 class="page-title">{{ 'ELIGIBILITY.BANNER.TITLE' | translate }}</h1>
            <div class="subtitle">
              {{ 'ELIGIBILITY.BANNER.SUBTITLE' | translate }}<br />
              <a [routerLink]="['/login']" class="btn btn-custom mt-3 mb-3 mb-md-0">
                {{ 'ELIGIBILITY.BANNER.LOGIN' | translate }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="banner-img">
          <img
            src="../../../assets/images/banner-ec.png"
            width="1000"
            height="585"
            alt="Eligibility Criteria"
            loading="lazy"
            class="image-style-wide"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Application Form -->
  <section class="main-top-wrapper">
    <div class="container">
      <div class="row">
        <form (ngSubmit)="apkpk_one()" [formGroup]="applicForm" novalidate>
          <div class="col-12">
            <div class="row">
              <div class="col-md-6">
                <section class="ques-hld mright" [ngClass]="{ disabled: !enablediv.includes('div1') }">
                  <div class="number">1</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD1.TITLE' | translate }}</label>
                    <select class="form-control" (change)="divenable()" formControlName="nationality" id="nationality">
                      <option value="Malaysian">{{ 'ELIGIBILITY.FORM.FIELD1.VALUE1' | translate }}</option>
                      <option value="Non-Malaysian">{{ 'ELIGIBILITY.FORM.FIELD1.VALUE2' | translate }}</option>
                    </select>
                    <small
                      *ngIf="applicForm.controls['nationality'].errors?.['required'] && !applicForm.controls['nationality'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'COMMON.NATIONALITYREQUIRED' | translate }}
                    </small>
                    <small
                      *ngIf="applicForm.controls['nationality'].errors?.['NationalityValidators']"
                      class="text-danger"
                    >
                      {{ 'ELIGIBILITY.FORM.FIELD1.ERROR1' | translate }}:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD1.ERROR2' | translate }}.</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD1.ERROR3' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD1.ERROR4' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul></small
                    >
                  </div>
                </section>

                <!--section class="ques-hld mright" [class.disabled]="!enablediv.includes('div3') || ageerror"-->
                <section class="ques-hld mright" [class.disabled]="!enablediv.includes('div2')">
                  <div class="number">2</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD3.TITLE' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      (change)="post_code_validate(); divenable()"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5"
                      formControlName="postcode"
                      id="staticEmail2"
                      value=""
                    />

                    <div *ngIf="applicForm.controls['postcode'].errors?.['minlength']" class="text-danger">
                      {{ 'APPLICATION.FORM1.FIELD11ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['postcode'].errors?.['maxlength']" class="text-danger">
                      {{ 'APPLICATION.FORM1.FIELD11ERROR3' | translate }}
                    </div>
                    <div
                      *ngIf="postcodeerror && !applicForm.controls['postcode'].errors?.['required']"
                      class="text-danger"
                      translate
                    >
                      {{ 'ELIGIBILITY.FORM.FIELD3.ERROR1' | translate }}:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD3.ERROR2' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD3.ERROR3' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD3.ERROR4' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul>
                    </div>
                    <small *ngIf="applicForm.controls['postcode'].errors?.['maxlength']" class="text-danger">
                      {{ 'ELIGIBILITY.FORM.FIELD3.ERROR1' | translate }}:-:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD3.ERROR2' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD3.ERROR3' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD3.ERROR4' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul></small
                    >
                  </div>
                </section>

                <!--section class="ques-hld mright" [ngClass]="{ disabled: !enablediv.includes('div2') }"-->
                <section
                  class="ques-hld mright"
                  [class.disabled]="!enablediv.includes('div4') || postcodeerror || applicForm.controls['postcode'].errors?.['maxlength'] || applicForm.controls['postcode'].errors?.['minlength'] ||  applicForm.controls['postcode'].errors?.['required']"
                >
                  <div class="number">3</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD2.TITLE' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="dob"
                      max="9999-12-31"
                      formControlName="dob"
                      (change)="check_date($event)"
                      maxlength="10"
                      minlength="10"
                      placeholder="dd/mm/yyyy"
                      pattern="[0-9]{2}/[0-9]{2}/(19|20)\d{2}$"
                      onkeypress="return event.charCode >= 8 && event.charCode <= 57"
                    />

                    <small
                      *ngIf="applicForm.controls['dob'].errors?.['required'] && applicForm.controls['dob'].touched || ageerror && applicForm.controls['dob'].touched"
                      class="text-danger"
                      >{{ 'APPLICATION.FORM1.FIELD4ERROR1' | translate }}</small
                    >
                    <small *ngIf="ageerror" class="text-danger"
                      >{{ 'ELIGIBILITY.FORM.FIELD2.ERROR1' | translate }}:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD2.ERROR2' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD2.ERROR3' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul></small
                    >
                  </div>
                </section>

                <!--section
                  class="ques-hld mright"
                  [class.disabled]="!enablediv.includes('div4') || postcodeerror || applicForm.controls['postcode'].errors?.['maxlength'] || applicForm.controls['postcode'].errors?.['minlength'] ||  applicForm.controls['postcode'].errors?.['required']"
                -->
                <section
                  class="ques-hld mright"
                  [class.disabled]="ageerror || !enablediv.includes('div4') || applicForm.controls['dob'].errors?.['required']"
                >
                  <div class="number">4</div>
                  <div class="form-group">
                    <label class="ques-txt" for=""> {{ 'ELIGIBILITY.FORM.FIELD4.TITLE' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="present_house"
                      type="radio"
                      name="present_house"
                      (click)="check_property(1); divenable()"
                      id="inlineRadio1"
                      value="true"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD4.VALUE1' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="present_house"
                      type="radio"
                      name="present_house"
                      (click)="check_property(0); divenable()"
                      id="inlineRadio2"
                      value="false"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD4.VALUE2' | translate }}</label>
                  </div>
                  <div class="row col-lg-12" *ngIf="propertyerror">
                    <small class="text-danger">
                      {{ 'ELIGIBILITY.FORM.FIELD4.ERROR1' | translate }}:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD4.ERROR2' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD4.ERROR3' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD4.ERROR4' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul>
                    </small>
                  </div>
                </section>

                <section class="ques-hld mright" [class.disabled]="propertyerror || !enablediv.includes('div5')">
                  <div class="number">5</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD5.TITLE' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="freehold"
                      type="radio"
                      name="freehold"
                      (click)="check_lease(1); divenable()"
                      id="inlineRadio1"
                      value="true"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD5.VALUE1' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="freehold"
                      type="radio"
                      name="freehold"
                      (click)="check_lease(0); _reseDiv5Child(); divenable()"
                      id="inlineRadio2"
                      value="false"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD5.VALUE2' | translate }}</label>
                    <small
                      *ngIf="applicForm.controls['freehold'].errors?.['required'] && !applicForm.controls['freehold'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'COMMON.FIELDREQUIRED' | translate }}
                    </small>
                  </div>

                  <section class="ques-hld-sub mt-4" *ngIf="leaseerror">
                    <div class="form-group">
                      <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD51.TITLE' | translate }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        formControlName="lease_expiry_date"
                        type="radio"
                        (click)="check_leaseexpire(1); divenable()"
                        name="lease_expiry_date"
                        id="inlineRadio1"
                        value="true"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD51.VALUE1' | translate }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        formControlName="lease_expiry_date"
                        type="radio"
                        (click)="check_leaseexpire(0); divenable()"
                        name="lease_expiry_date"
                        id="inlineRadio2"
                        value="false"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD51.VALUE2' | translate }}</label>
                    </div>
                    <div class="row col-lg-12" *ngIf="leaseexpireerror">
                      <small style="color: #0f5298">
                        {{ 'ELIGIBILITY.FORM.FIELD51.ERROR1' | translate }}:-
                        <ul>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD51.ERROR2' | translate }}</li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD51.ERROR3' | translate }}</li>
                        </ul>
                      </small>
                    </div>
                  </section>
                </section>

                <section class="ques-hld mright" [ngClass]="{ disabled: !enablediv.includes('div6') }">
                  <div class="number">6</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD6.TITLE' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="sole_owner"
                      type="radio"
                      name="sole_owner"
                      (click)="check_owner(1); divenable()"
                      id="inlineRadio1"
                      value="true"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="sole_owner"
                      type="radio"
                      name="sole_owner"
                      (click)="check_owner(0); _resetTAb6Child()"
                      id="inlineRadio2"
                      value="false"
                    />
                    <label class="form-check-label" for=""> {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label>
                  </div>

                  <section class="ques-hld-sub mt-4" *ngIf="ownererror === 1">
                    <div class="form-group">
                      <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD62.TITLE' | translate }} </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="personlimit"
                        (click)="check_personlimit(0); divenable()"
                        name="personlimit"
                        id="inlineRadio2"
                        value="false"
                      />
                      <label class="form-check-label" for="">
                        {{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label
                      >
                      <small
                        *ngIf="applicForm.controls['personlimit'].errors?.['required'] && !applicForm.controls['personlimit'].untouched"
                        class="text-danger"
                        translate
                      >
                        {{ 'COMMON.FIELDREQUIRED' | translate }}
                      </small>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="personlimit"
                        (click)="check_personlimit(1); divenable()"
                        name="personlimit"
                        id="inlineRadio1"
                        value="true"
                      />
                      <label class="form-check-label" for="">
                        {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label
                      >
                    </div>
                    <div class="row col-lg-12" *ngIf="personlimiterror === 1">
                      <small class="text-danger">
                        {{ 'ELIGIBILITY.FORM.FIELD61.ERROR1' | translate }}:-
                        <ul>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD61.ERROR2' | translate }}</li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD61.ERROR3' | translate }}</li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD61.ERROR4' | translate }}</li>
                          <li>
                            <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                              >https://ssb.cagamas.com.my/contact-us</a
                            >
                          </li>
                        </ul>
                      </small>
                    </div>
                  </section>

                  <section class="ques-hld-sub mt-4" *ngIf="personlimiterror === 0">
                    <div class="form-group">
                      <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD63.TITLE' | translate }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="personrelation"
                        (click)="check_personrelation(0); divenable()"
                        name="personrelation"
                        id="inlineRadio2"
                        value="false"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD63.VALUE1' | translate }}</label>
                      <small
                        *ngIf="applicForm.controls['personrelation'].errors?.['required'] && !applicForm.controls['personrelation'].untouched"
                        class="text-danger"
                        translate
                      >
                        {{ 'COMMON.FIELDREQUIRED' | translate }}
                      </small>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="personrelation"
                        (click)="check_personrelation(1); divenable()"
                        name="personrelation"
                        id="inlineRadio1"
                        value="true"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD63.VALUE2' | translate }}</label>
                    </div>
                    <div class="row col-lg-12" *ngIf="personrelationerror === 1">
                      <small class="text-danger">
                        {{ 'ELIGIBILITY.FORM.FIELD63.ERROR1' | translate }}:-
                        <ul>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD63.ERROR2' | translate }}</li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD63.ERROR3' | translate }}</li>
                          <li>
                            <a href=" https://ssb.cagamas.com.my/eligibility-criteria" target="_blank">
                              https://ssb.cagamas.com.my/eligibility-criteria</a
                            >.
                          </li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD63.ERROR4' | translate }}</li>
                          <li>
                            <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                              >https://ssb.cagamas.com.my/contact-us</a
                            >
                          </li>
                        </ul>
                      </small>
                    </div>
                  </section>

                  <section class="ques-hld-sub mt-4" *ngIf="personrelationerror === 0">
                    <div class="form-group">
                      <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD64.TITLE' | translate }}?</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        formControlName="owneragelimit"
                        (click)="check_ownerage(0); divenable()"
                        type="radio"
                        name="owneragelimit"
                        id="inlineRadio2"
                        value="true"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        formControlName="owneragelimit"
                        (click)="check_ownerage(1); divenable()"
                        type="radio"
                        name="owneragelimit"
                        id="inlineRadio1"
                        value="false"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label>
                      <small
                        *ngIf="applicForm.controls['owneragelimit'].errors?.['required'] && !applicForm.controls['owneragelimit'].untouched"
                        class="text-danger"
                        translate
                      >
                        {{ 'COMMON.FIELDREQUIRED' | translate }}
                      </small>
                    </div>
                    <div class="row col-lg-12" *ngIf="ownerageerror">
                      <small class="text-danger">
                        {{ 'ELIGIBILITY.FORM.FIELD64.ERROR1' | translate }}:-
                        <ul>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD64.ERROR2' | translate }}</li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD64.ERROR3' | translate }}</li>
                          <li>
                            <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                              >https://ssb.cagamas.com.my/contact-us</a
                            >
                          </li>
                        </ul>
                      </small>
                    </div>
                  </section>
                </section>
              </div>
              <div class="col-md-6">
                <section class="ques-hld" [ngClass]="{ disabled: !enablediv.includes('div7') }">
                  <div class="number">7</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">
                      {{ 'ELIGIBILITY.FORM.FIELD7.TITLE' | translate }}<br />
                      <span>(i) {{ 'ELIGIBILITY.FORM.FIELD7.DESCRIPTION1' | translate }}</span>
                      <span>(ii) {{ 'ELIGIBILITY.FORM.FIELD7.DESCRIPTION2' | translate }}</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="joint"
                      (click)="check_joint(0); divenable()"
                      type="radio"
                      name="joint"
                      id="inlineRadio1"
                      value="false"
                    />
                    <label class="form-check-label" for=""> {{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="joint"
                      (click)="check_joint(1); divenable()"
                      type="radio"
                      name="joint"
                      id="inlineRadio2"
                      value="true"
                    />
                    <label class="form-check-label" for=""> {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label>
                  </div>
                  <div class="row col-lg-12" *ngIf="jointerror === 0">
                    <small class="text-danger">
                      {{ 'ELIGIBILITY.FORM.FIELD7.ERROR1' | translate }}:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD7.ERROR2' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD7.ERROR3' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul>
                    </small>
                  </div>
                </section>

                <section class="ques-hld" [ngClass]="{ disabled: !enablediv.includes('div8') }">
                  <div class="number">8</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD8.TITLE' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="outstanding_balns"
                      (click)="check_outstdbalerror(1); divenable()"
                      type="radio"
                      name="outstanding_balns"
                      id="inlineRadio1"
                      value="true"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="outstanding_balns"
                      (click)="check_outstdbalerror(0); divenable(); _resetTab8Child()"
                      type="radio"
                      name="outstanding_balns"
                      id="inlineRadio2"
                      value="false"
                    />
                    <label class="form-check-label" for=""> {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label>
                    <small
                      *ngIf="applicForm.controls['outstanding_balns'].errors?.['required'] && !applicForm.controls['outstanding_balns'].untouched"
                      class="text-danger"
                      translate
                    >
                      {{ 'COMMON.FIELDREQUIRED' | translate }}
                    </small>
                  </div>

                  <section class="ques-hld-sub mt-4" *ngIf="outstandingbalerror">
                    <div class="form-group">
                      <label class="ques-txt" for="">{{ 'ELIGIBILITY.FORM.FIELD81.TITLE' | translate }}</label>
                      <a class="ques-txt" target="_blank" href="https://ssb.cagamas.com.my/reverse-mortgage-calculator"
                        >https://ssb.cagamas.com.my/reverse-mortgage-calculator</a
                      >
                      <br />
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="outstandingbal"
                        (click)="check_outstdbalerr(1)"
                        name="outstandingbal"
                        id="inlineRadio1"
                        value="true"
                      />
                      <label class="form-check-label" for=""
                        >{{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="outstandingbal"
                        (click)="check_outstdbalerr(0)"
                        name="outstandingbal"
                        id="inlineRadio2"
                        value="false"
                      />
                      <label class="form-check-label" for="">
                        {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label
                      >
                      <small
                        *ngIf="applicForm.controls['outstandingbal'].errors?.['required'] && !applicForm.controls['outstandingbal'].untouched"
                        class="text-danger"
                        translate
                      >
                        {{ 'COMMON.FIELDREQUIRED' | translate }}
                      </small>
                    </div>
                  </section>

                  <section class="ques-hld-sub mt-4" *ngIf="outstdbalerr">
                    <div class="form-group">
                      <label class="ques-txt" for=""> {{ 'ELIGIBILITY.FORM.FIELD82.TITLE' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="outstandingbal2"
                        (click)="check_outstdbalerr2(1); outstandingbal2Enable()"
                        name="outstandingbal2"
                        id="inlineRadio1"
                        value="true"
                      />
                      <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        formControlName="outstandingbal2"
                        (click)="check_outstdbalerr2(0); outstandingbal2Enable()"
                        name="outstandingbal2"
                        id="inlineRadio2"
                        value="false"
                      />
                      <label class="form-check-label" for="">
                        {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label
                      >
                    </div>

                    <div class="row col-lg-12" *ngIf="outstdbalerr2">
                      <small class="text-danger">
                        {{ 'ELIGIBILITY.FORM.FIELD7.ERROR1' | translate }}:-
                        <ul>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD7.ERROR2' | translate }}</li>
                          <li>{{ 'ELIGIBILITY.FORM.FIELD7.ERROR3' | translate }}</li>
                          <li>
                            <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                              >https://ssb.cagamas.com.my/contact-us</a
                            >
                          </li>
                        </ul>
                      </small>
                    </div>
                  </section>
                </section>

                <section class="ques-hld" [ngClass]="{ disabled: !enablediv.includes('div9') }">
                  <div class="number">9</div>
                  <div class="form-group">
                    <label class="ques-txt" for="">
                      {{ 'ELIGIBILITY.FORM.FIELD9.TITLE' | translate }}<br />
                      <span>{{ 'ELIGIBILITY.FORM.FIELD9.TEXT' | translate }}</span>
                      <span>a.{{ 'ELIGIBILITY.FORM.FIELD9.TEXT1' | translate }}</span>
                      <span>b.{{ 'ELIGIBILITY.FORM.FIELD9.TEXT2' | translate }}</span>
                      <span>c.{{ 'ELIGIBILITY.FORM.FIELD9.TEXT3' | translate }}</span>
                      <span>d.{{ 'ELIGIBILITY.FORM.FIELD9.TEXT4' | translate }}</span>
                      <span>e.{{ 'ELIGIBILITY.FORM.FIELD9.TEXT5' | translate }}</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="property_encumbered"
                      (click)="check_propertyencumerr(1)"
                      type="radio"
                      name="property_encumbered"
                      id="inlineRadio1"
                      value="true"
                    />
                    <label class="form-check-label" for="">{{ 'ELIGIBILITY.FORM.FIELD64.VALUE1' | translate }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      formControlName="property_encumbered"
                      (click)="check_propertyencumerr(0)"
                      type="radio"
                      name="property_encumbered"
                      id="inlineRadio2"
                      value="false"
                    />
                    <label class="form-check-label" for=""> {{ 'ELIGIBILITY.FORM.FIELD64.VALUE2' | translate }}</label>
                  </div>

                  <div class="row col-lg-12" *ngIf="propertyencumerr">
                    <small class="text-danger">
                      {{ 'ELIGIBILITY.FORM.FIELD7.ERROR1' | translate }}:-
                      <ul>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD7.ERROR2' | translate }}</li>
                        <li>{{ 'ELIGIBILITY.FORM.FIELD7.ERROR3' | translate }}</li>
                        <li>
                          <a href="https://ssb.cagamas.com.my/contact-us" target="_blank"
                            >https://ssb.cagamas.com.my/contact-us</a
                          >
                        </li>
                      </ul>
                    </small>
                  </div>
                </section>
              </div>
              <div class="col-md-12 my-4">
                <button
                  type="submit"
                  class="btn btn-custom"
                  (click)="submitEligibility()"
                  [disabled]="applicForm.invalid || propertyencumerr"
                >
                  {{ 'REGISTRATION.BUTTON2' | translate }}
                </button>
                <!-- <button
                type="reset"
                class="btn btn-custom"
                (click)="reset()"

              >
                Reset
              </button> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

  <!-- Footer Start-->
  <footer class="site-footer">
    <div class="site-footer__bottom">
      <div class="container">
        <section class="region region-footer-fifth">
          <div id="block-footercopyright" class="block-copyright block block-block-content">
            <div class="content">
              <div
                class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
              >
                <div class="left-col">
                  <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </footer>
  <!-- Footer End-->
</div>
