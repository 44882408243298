import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from './authentication.service';
import { CredentialsService } from './credentials.service';
import Swal from 'sweetalert2';
import { browserRefresh } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  _requestFrom: string = '';
  defaultPass: boolean = false;
  errormessage: string | undefined;
  apierror: string = '';
  apierror2: string = '';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private credentialService: CredentialsService
  ) {
    this.createForm();
    localStorage.removeItem('bearer');
    //localStorage.clear();
    localStorage.removeItem('credentials');
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this._requestFrom = data['requestFrom'];
      //console.log(this._requestFrom);
    });
    this.translate.get('LOGIN').subscribe((data: any) => {
      this.apierror = data.FORM.APIERROR;
      this.apierror2 = data.FORM.APIERROR2;
    });
    if (browserRefresh == true) {
      this.router.navigate(['/']);
    }
    localStorage.removeItem('username');
    localStorage.removeItem('bearer');
    //localStorage.clear();
    localStorage.removeItem('credentials');

    if (localStorage.getItem('language') != null && localStorage.getItem('language') != '') {
      const lang = localStorage.getItem('language');
      console.log('lang' + lang);
      this.translate.setDefaultLang(lang ? lang : '');
      this.translate.use(lang ? lang : '');
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
  }

  _onSubmit = async () => {
    this.isLoading = true;
    const login$ = this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password);
    await login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (credentials) => {
          log.debug(`${credentials.username} successfully logged in`);
          const data = {
            username: credentials.username,
            token: credentials.jwt,
            role: credentials.role,
            email: credentials.email,
          };
          this.credentialService.setCredentials(data, this.loginForm.value.remember);
          let username = credentials.username;
          let tokem = data.token;
          localStorage.setItem('username', username);
          localStorage.setItem('bearer', credentials.jwt);
          if (localStorage.getItem('bearer') == tokem && data.role === 'ROLE_CUSTOMER') {
            //this.router.navigate(['/dashboard']);
            window.location.replace('/dashboard');
          }

          if 
          (localStorage.getItem('bearer') == tokem && data.role === 'ROLE_IT_ADMIN')
           {
          // this.router.navigate(['/admin']);
            window.location.replace('/admin-panel');
            // window.location.replace('/admin-panel');
        }
            
          if 
            (localStorage.getItem('bearer') == tokem && data.role === 'ROLE_CAGAMAS_USER')
             {
              console.log(data, 'lllllllllll');
              
            //this.router.navigate(['/user-list']);
            window.location.replace('/user-list');
          }
        },
        (error) => {
          if (error.error.message === 'User account is not Active, please contact system administrator.') {
            this.error = error;
            this.error = error;
            this.errormessage = this.apierror;
          } else {
            this.error = error;
            this.error = error;
            this.errormessage = this.apierror2;
          }
        }
      );
  };

  // _onSubmit = () => {
  //   if (this._requestFrom == 'ROLE_CUSTOMER') {
  //     this.login();
  //   }
  //   if (this._requestFrom == 'ROLE_CAGAMAS_USER') {
  //     this.cagmasLogin();
  //   }
  // };
  _forgotPassword = () => {
    if (this._requestFrom == 'ROLE_CUSTOMER') {
      this.router.navigate(['/forgot-password']);
    }
    if (this._requestFrom == 'ROLE_CAGAMAS_USER') {
      this.router.navigate(['/cagamas-user/forgot-password']);
    }
    if (this._requestFrom == 'ROLE_IT_ADMIN') {
      this.router.navigate(['/admin/forgot-password']);
    }
  };

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }
  togglepassword = (requestFrom: string) => {
    if (requestFrom == 'P') {
      this.defaultPass = !this.defaultPass;
    }
  };
}
