import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService } from './authentication.service';
import { CustomValidators } from '../../providers/CustomValidators';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss'],
})
export class ForgotPassComponent implements OnInit {
  error: string | undefined;
  forgotpassForm!: FormGroup;
  isLoading = false;
  queryParamsStatus: any;
  _requestFrom: string = '';
  succmess: string = '';
  succhead: string = '';
  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.translate.get('FORGOT').subscribe((data: any) => {
      this.succmess = data.FORM.SUCCMESS;
      this.succhead = data.FORM.SUCCHEAD;
    });
    this.route.params.subscribe((params: Params) => {
      this.queryParamsStatus = params['emailId'];
      console.log(this.queryParamsStatus);
    });
    this.route.data.subscribe((data) => {
      this._requestFrom = data['requestFrom'];
    });
    this._onSubmit();
    console.log('FOR', this._requestFrom);
  }
  _redirectLogin = () => {
    if (this._requestFrom == 'ROLE_CUSTOMER') {
      this.router.navigate(['/login']);
    }
    if (this._requestFrom == 'ROLE_CAGAMAS_USER') {
      this.router.navigate(['/cagamas-user/login']);
    }
    if (this._requestFrom == 'ROLE_IT_ADMIN') {
      this.router.navigate(['/admin/login']);
    }
  };

  _onSubmit = () => {
    //  if( this._requestFrom=='user'){
    // this.forgot_pass();
    //  }
    // if( this._requestFrom=='cagamas'){
    //   this.cagmasForgotPassword();
    // }
     if (this._requestFrom == 'ROLE_CUSTOMER') {
      this.forgot_pass();
    }
    if (this._requestFrom == 'ROLE_CAGAMAS_USER') {
      this.cagmasForgotPassword();
    }
    if (this._requestFrom == 'ROLE_IT_ADMIN') {
      this.cagmasForgotPassword();
    }
  };
  cagmasForgotPassword = () => {
    alert('Cagmas Forgot Password');
  };
  private createForm() {
    this.forgotpassForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
          ],
        ],
        confirmPassword: ['', Validators.required],

         moblieno: [
          '',
          [
            Validators.required,
             Validators.maxLength(4),
            Validators.pattern('^[0-9]*$'),        
            // Validators.pattern('^(?=(?:.{10}|.{11})$)[0-9]*$'),   
          ],
        ],
        
      },
      {
        validator: CustomValidators('password', 'confirmPassword'),
      }
    );
  }

  forgot_pass() {
    this.isLoading = true;
    if (this.forgotpassForm.valid) {
      var body = {
        email: this.queryParamsStatus,
        newPassword: this.forgotpassForm.value.password,
        confirmPassword: this.forgotpassForm.value.confirmPassword,
        moblieno:this.forgotpassForm.value.moblieno,
      };
      this.authenticationService
        .forgot_password(body)
        .pipe(
          finalize(() => {
            this.forgotpassForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (credentials) => {
            console.log(credentials);
            Swal.fire({
              icon: 'success',
              title: this.succhead,
              text: this.succmess,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'Ok',
            }).then((result) => {
              console.log(result);
              if (result.isConfirmed) {
                this.router.navigate(['/login']);
              } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
              }
            });
          },
          (error) => {
            console.log(error);
            if (error.statusText == 'success') {
              Swal.fire({ icon: 'success', title: this.succhead, text: this.succmess });
            } else {
              Swal.fire({ icon: 'error', title: 'Oops...', text: error.error.message });
            }
          }
        );
    } else {
      this.markFormGroupTouched(this.forgotpassForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
}
