<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css" />
<section class="application main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <h2 class="heading mt-3">{{ 'APPLICATION.FORM1.TITLE' | translate }}</h2>
          </div>
          <div class="col-md-3 d-flex justify-content-md-end align-items-center">
            <div class="application-logo-text">
              <h3>{{ 'APPLICATION.FORM1.CONFIDENTIAL' | translate }}</h3>
              <h5>{{ 'APPLICATION.FORM1.VERSION' | translate }}</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="application_form col-12">
        <form (ngSubmit)="apkpk_one()" [formGroup]="applicForm" novalidate>
          <div>
            <div class="form-blue-bg mb-5">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="para-title">{{ 'APPLICATION.FORM3.FIELD1LEVEL' | translate }}</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="" class="control-label">{{ 'APPLICATION.FORM1.FIELD3TEXT' | translate }} : </label>
                    <select id="salutation " formControlName="salutation" class="form-control">
                      <option value="Mr">{{ 'APPLICATION.FORM1.FIELD3VALUE1' | translate }}</option>
                      <option value="Mdm">{{ 'APPLICATION.FORM1.FIELD3VALUE2' | translate }}</option>
                      <option value="Ms">{{ 'APPLICATION.FORM1.FIELD3VALUE3' | translate }}</option>
                      <option value="Tan Sri">{{ 'APPLICATION.FORM1.FIELD3VALUE4' | translate }}</option>
                      <option value="Dato’">{{ 'APPLICATION.FORM1.FIELD3VALUE5' | translate }}</option>
                      <option value="Other">{{ 'APPLICATION.FORM1.FIELD3VALUE6' | translate }}</option>
                    </select>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="name" class="control-label">{{ 'APPLICATION.FORM1.FIELD5TEXT' | translate }} : </label>
                    <input type="text" formControlName="applicant_name" class="form-control" id="name" />
                    <div
                      [hidden]="
                        applicForm.controls['applicant_name'].valid || applicForm.controls['applicant_name'].untouched
                      "
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD5ERROR1' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="nric" class="control-label">{{ 'APPLICATION.FORM1.FIELD7TEXT' | translate }} </label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="nric_no"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 12"
                      id="nric"
                    />
                    <div
                      *ngIf="applicForm.controls['nric_no'].errors?.['required'] && applicForm.controls['nric_no'].touched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD7ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['nric_no'].errors?.['minlength']" class="text-danger">
                      {{ 'APPLICATION.FORM1.FIELD7ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['nric_no'].errors?.['maxlength']" class="text-danger">
                      {{ 'APPLICATION.FORM1.FIELD7ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="address" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD9TEXT' | translate }} :
                    </label>
                    <input type="text" class="form-control" formControlName="res_address" id="address" />
                    <div
                      *ngIf="applicForm.controls['res_address'].errors?.['required'] && applicForm.controls['res_address'].touched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD9ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="post" class="control-label">{{ 'APPLICATION.FORM1.FIELD11TEXT' | translate }} : </label>
                    <input
                      class="form-control"
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5"
                      onpaste="return false"
                      formControlName="postcode"
                      id="post"
                    />
                    <div
                      *ngIf="applicForm.controls['postcode'].errors?.['required'] && applicForm.controls['postcode'].touched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD11ERROR' | translate }}
                    </div>
                    <div
                      *ngIf="postcodeerror && !applicForm.controls['postcode'].errors?.['required']"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD11ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['postcode'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD11ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['postcode'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD11ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="email" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD13TEXT' | translate }} :
                    </label>
                    <input type="email" class="form-control" formControlName="email" id="email" />
                    <div
                      *ngIf="applicForm.controls['email'].errors?.['required'] && !applicForm.controls['email'].untouched"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM1.FIELD13ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['email'].errors?.['pattern']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD13ERROR2' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="tel" class="control-label">{{ 'APPLICATION.FORM1.FIELD17TEXT' | translate }} : </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="res_tel"
                      id="tel"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                      onpaste="return false"
                    />
                    <div *ngIf="applicForm.controls['res_tel'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM3.FIELD5ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['res_tel'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD17ERROR2' | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group required mb-4">
                    <label for="telNo" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD15TEXT' | translate }} :
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                      onpaste="return false"
                      formControlName="hp_tel"
                      id="telNo"
                    />
                    <div
                      *ngIf="applicForm.controls['hp_tel'].errors?.['required'] && applicForm.controls['hp_tel'].touched"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM1.FIELD15ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['hp_tel'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD15ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['hp_tel'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD15ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="dob_box form-group required mb-4">
                    <label for="birthday" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD4TEXT' | translate }} :</label
                    >
                    <input
                      class="form-control"
                      type="date"
                      id="dob"
                      max="9999-12-31"
                      (change)="check_date($event)"
                      formControlName="dob"
                    />
                    <div
                      *ngIf="applicForm.controls['dob'].errors?.['required'] && applicForm.controls['dob'].touched"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM1.FIELD4ERROR1' | translate }}
                    </div>
                    <div *ngIf="ageerror" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM3.FIELD4ERROR1' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="" class="d-block control-label"
                      >{{ 'APPLICATION.FORM1.FIELD10TEXT' | translate }} :
                    </label>
                    <div class="form-check form-check-inline">
                      <input type="radio" id="male" class="form-check-input" formControlName="sex" value="Male" />
                      <label for="male" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD10VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="radio" id="female" class="form-check-input" formControlName="sex" value="Female" />
                      <label for="female" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD10VALUE2' | translate }}</label>
                    </div>
                    <div
                      *ngIf="applicForm.controls['sex'].errors?.['required'] && !applicForm.controls['sex'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD10ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="Race" class="control-label">{{ 'APPLICATION.FORM1.FIELD6TEXT' | translate }} : </label>
                    <select formControlName="race" id="Race" class="form-control">
                      <option value="Malay">{{ 'APPLICATION.FORM1.FIELD6VALUE1' | translate }}</option>
                      <option value="Chinese">{{ 'APPLICATION.FORM1.FIELD6VALUE2' | translate }}</option>
                      <option value="Indian">{{ 'APPLICATION.FORM1.FIELD6VALUE3' | translate }}</option>
                      <option value="Others - Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE4' | translate }}</option>
                      <option value="Others - Non Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE5' | translate }}</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['race'].errors?.['required'] && applicForm.controls['race'].touched"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM1.FIELD6ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="nationality" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD8TEXT' | translate }} :
                    </label>
                    <select
                      formControlName="nationality"
                      id="nationality"
                      (change)="nationalityVerify()"
                      class="form-control"
                    >
                      <option value="Malaysian">{{ 'APPLICATION.FORM3.FIELD5VALUE2' | translate }}</option>
                      <option value="Non-Malaysian">{{ 'APPLICATION.FORM3.FIELD5VALUE1' | translate }}</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['nationality'].errors?.['required'] && !applicForm.controls['nationality'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD8ERROR1' | translate }}
                    </div>
                    <!-- <div *ngIf="nationalitynoterror" class="text-danger mt-1">Only Malaysian can apply!</div> -->
                  </div>

                  <div class="form-group required mb-4">
                    <label for="marital" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD12TEXT' | translate }} :
                    </label>
                    <select formControlName="marital_status" id="marital" class="form-control">
                      <option value="Married">{{ 'APPLICATION.FORM1.FIELD12VALUE1' | translate }}</option>
                      <option value="Single">{{ 'APPLICATION.FORM1.FIELD12VALUE2' | translate }}</option>
                      <option value="Widowed">{{ 'APPLICATION.FORM1.FIELD12VALUE3' | translate }}</option>
                      <option value="Divorced">{{ 'APPLICATION.FORM1.FIELD12VALUE4' | translate }}</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['marital_status'].errors?.['required'] && !applicForm.controls['marital_status'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD12ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="relationship" class="control-label">
                      {{ 'APPLICATION.FORM3.FIELD2TEXT' | translate }} :
                    </label>
                    <select formControlName="relationship" id="relationship" class="form-control">
                      <option value="Spouse">{{ 'APPLICATION.FORM3.FIELD2VALUE1' | translate }}</option>
                      <option value="Children">{{ 'APPLICATION.FORM3.FIELD2VALUE2' | translate }}</option>
                      <option value="Parent">{{ 'APPLICATION.FORM3.FIELD2VALUE3' | translate }}</option>
                      <option value="Siblings">{{ 'APPLICATION.FORM3.FIELD2VALUE4' | translate }}</option>
                      <option value="Cousin">{{ 'APPLICATION.FORM3.FIELD2VALUE5' | translate }}</option>
                      <option value="Niece/Nephew">{{ 'APPLICATION.FORM3.FIELD2VALUE6' | translate }}</option>
                      <option value="Friend">{{ 'APPLICATION.FORM3.FIELD2VALUE7' | translate }}</option>
                      <option value="Other">{{ 'APPLICATION.FORM3.FIELD2VALUE8' | translate }}</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['relationship'].errors?.['required'] && !applicForm.controls['relationship'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM3.FIELD2ERROR' | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-md-12 pt-4">
                  <h4 class="para-title">{{ 'APPLICATION.FORM3.FIELD3LEVEL' | translate }}</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="" class="control-label">{{ 'APPLICATION.FORM1.FIELD3TEXT' | translate }} : </label>
                    <select id="salutation " formControlName="salutation2" class="form-control">
                      <option value="Mr">{{ 'APPLICATION.FORM1.FIELD3VALUE1' | translate }}</option>
                      <option value="Mdm">{{ 'APPLICATION.FORM1.FIELD3VALUE2' | translate }}</option>
                      <option value="Ms">{{ 'APPLICATION.FORM1.FIELD3VALUE3' | translate }}</option>
                      <option value="Tan Sri">{{ 'APPLICATION.FORM1.FIELD3VALUE4' | translate }}</option>
                      <option value="Dato’">{{ 'APPLICATION.FORM1.FIELD3VALUE5' | translate }}</option>
                      <option value="Other">{{ 'APPLICATION.FORM1.FIELD3VALUE6' | translate }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name" class="control-label">{{ 'APPLICATION.FORM1.FIELD5TEXT' | translate }} : </label>
                    <input type="text" formControlName="applicant_name2" class="form-control" id="name" />
                  </div>

                  <div class="form-group mb-4">
                    <label for="nric" class="control-label">{{ 'APPLICATION.FORM1.FIELD7TEXT' | translate }} </label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="nric_no2"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 12"
                      id="nric"
                    />

                    <div *ngIf="applicForm.controls['nric_no'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD7ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['nric_no'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD7ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="address" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD9TEXT' | translate }} :
                    </label>
                    <input type="text" class="form-control" formControlName="res_address2" id="address" />
                  </div>

                  <div class="form-group mb-4">
                    <label for="post" class="control-label">{{ 'APPLICATION.FORM1.FIELD11TEXT' | translate }} : </label>
                    <input
                      class="form-control"
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5"
                      onpaste="return false"
                      formControlName="postcode2"
                      id="post"
                    />

                    <div
                      *ngIf="propertypostcodeerror && !applicForm.controls['postcode2'].errors?.['required']"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD11ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['postcode2'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD11ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['postcode2'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD11ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="email" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD13TEXT' | translate }} :
                    </label>
                    <input type="email" class="form-control" formControlName="email2" id="email" />

                    <div *ngIf="applicForm.controls['email2'].errors?.['pattern']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD13ERROR2' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="tel" class="control-label">{{ 'APPLICATION.FORM1.FIELD17TEXT' | translate }} : </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="res_tel2"
                      id="tel"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                      onpaste="return false"
                    />
                    <div *ngIf="applicForm.controls['res_tel2'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM3.FIELD5ERROR1' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['res_tel2'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD17ERROR2' | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <label for="telNo" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD15TEXT' | translate }} :
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                      onpaste="return false"
                      formControlName="hp_tel2"
                      id="telNo"
                    />
                    <div *ngIf="applicForm.controls['hp_tel2'].errors?.['minlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD15ERROR2' | translate }}
                    </div>
                    <div *ngIf="applicForm.controls['hp_tel2'].errors?.['maxlength']" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM1.FIELD15ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="dob_box form-group mb-4">
                    <label for="birthday" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD4TEXT' | translate }} :</label
                    >
                    <input
                      class="form-control"
                      type="date"
                      id="dob"
                      max="9999-12-31"
                      (change)="check_date1($event)"
                      formControlName="dob2"
                    />

                    <div *ngIf="ageerror1" class="text-danger mt-1">
                      {{ 'APPLICATION.FORM3.FIELD4ERROR1' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="" class="d-block control-label"
                      >{{ 'APPLICATION.FORM1.FIELD10TEXT' | translate }} :
                    </label>
                    <div class="form-check form-check-inline">
                      <input type="radio" id="male" class="form-check-input" formControlName="sex2" value="Male" />
                      <label for="male" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD10VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="radio" id="female" class="form-check-input" formControlName="sex2" value="Female" />
                      <label for="female" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD10VALUE2' | translate }}</label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="Race" class="control-label">{{ 'APPLICATION.FORM1.FIELD6TEXT' | translate }} : </label>
                    <select formControlName="race2" id="Race" class="form-control">
                      <option value="Malay">{{ 'APPLICATION.FORM1.FIELD6VALUE1' | translate }}</option>
                      <option value="Chinese">{{ 'APPLICATION.FORM1.FIELD6VALUE2' | translate }}</option>
                      <option value="Indian">{{ 'APPLICATION.FORM1.FIELD6VALUE3' | translate }}</option>
                      <option value="Others - Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE4' | translate }}</option>
                      <option value="Others - Non Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE5' | translate }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="nationality" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD8TEXT' | translate }} :
                    </label>
                    <select formControlName="nationality2" id="nationality" class="form-control">
                      <option value="Malaysian">{{ 'APPLICATION.FORM3.FIELD5VALUE2' | translate }}</option>
                      <option value="Non-Malaysian">{{ 'APPLICATION.FORM3.FIELD5VALUE1' | translate }}</option>
                    </select>

                    <div
                      *ngIf="applicForm.controls['nationality2'].errors?.['NationalityValidators']"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM1.FIELD8ERROR1' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="marital" class="control-label"
                      >{{ 'APPLICATION.FORM1.FIELD12TEXT' | translate }} :
                    </label>
                    <select formControlName="marital_status2" id="marital" class="form-control">
                      <option value="Married">{{ 'APPLICATION.FORM1.FIELD12VALUE1' | translate }}</option>
                      <option value="Single">{{ 'APPLICATION.FORM1.FIELD12VALUE2' | translate }}</option>
                      <option value="Widowed">{{ 'APPLICATION.FORM1.FIELD12VALUE3' | translate }}</option>
                      <option value="Divorced">{{ 'APPLICATION.FORM1.FIELD12VALUE4' | translate }}</option>
                    </select>
                  </div>

                  <div class="form-group mb-4">
                    <label for="relationship2" class="control-label"
                      >{{ 'APPLICATION.FORM3.FIELD2TEXT' | translate }} :
                    </label>
                    <select formControlName="relationship2" id="relationship2" class="form-control">
                      <option value="Spouse">{{ 'APPLICATION.FORM3.FIELD2VALUE1' | translate }}</option>
                      <option value="Children">{{ 'APPLICATION.FORM3.FIELD2VALUE2' | translate }}</option>
                      <option value="Parent">{{ 'APPLICATION.FORM3.FIELD2VALUE3' | translate }}</option>
                      <option value="Siblings">{{ 'APPLICATION.FORM3.FIELD2VALUE4' | translate }}</option>
                      <option value="Cousin">{{ 'APPLICATION.FORM3.FIELD2VALUE5' | translate }}</option>
                      <option value="Niece/Nephew">{{ 'APPLICATION.FORM3.FIELD2VALUE6' | translate }}</option>
                      <option value="Friend">{{ 'APPLICATION.FORM3.FIELD2VALUE7' | translate }}</option>
                      <option value="Other">{{ 'APPLICATION.FORM3.FIELD2VALUE8' | translate }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12 pt-3">
                  <p style="text-align: justify">
                    {{ 'APPLICATION.FORM3.FORM3DESCRIPTION1' | translate }}
                  </p>
                  <div class="row my-3">
                    <div class="col-md-1 pt-3"></div>
                    <div class="col-md-10 pt-3">
                      <div class="p-3" style="border: 1px solid">
                        <p style="text-align: justify">
                          <strong>{{ 'APPLICATION.FORM3.FORM3DESCRIPTION2HEADING' | translate }}</strong>
                        </p>
                        <p style="text-align: justify">
                          {{ 'APPLICATION.FORM3.FORM3DESCRIPTION2' | translate }}
                        </p>
                        <p>
                          <strong>
                            <!-- <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" formControlName="agree" value="Yes" />
                            <label for="male" class="mt-1 mx-2">I/We hereby give my/our consent(s) to Cagamas Berhad to:- </label>
                          </div> -->
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-1 pt-3"></div>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="checkbox" class="form-check-input" formControlName="agree" value="Yes" />
                    <label for="male" class="mt-1 mx-2"
                      >{{ 'APPLICATION.FORM3.FORM3DESCRIPTION3' | translate }}:-
                    </label>
                  </div>
                  <div
                    *ngIf="applicForm.controls['agree'].errors?.['required'] && applicForm.controls['agree'].touched"
                    class="text-danger mt-1"
                    style=""
                  >
                    {{ 'APPLICATION.FORM3.FORM3DESCRIPTIONERROR' | translate }}
                  </div>
                  <ul style="text-align: justify">
                    <li>{{ 'APPLICATION.FORM3.FORM3DESCRIPTION4LI1' | translate }};</li>
                    <li>
                      {{ 'APPLICATION.FORM3.FORM3DESCRIPTION4LI2' | translate }}
                    </li>
                    <li>
                      {{ 'APPLICATION.FORM3.FORM3DESCRIPTION4LI3' | translate }}
                    </li>
                    <li>{{ 'APPLICATION.FORM3.FORM3DESCRIPTON4LI4' | translate }}.</li>
                  </ul>

                  <p style="text-align: justify">{{ 'APPLICATION.FORM3.FORM3DESCRIPTION4PARA1' | translate }}.</p>
                  <p style="text-align: justify">{{ 'APPLICATION.FORM3.FORM3DESCRIPTION4PARA2' | translate }}.</p>
                  <p style="text-align: justify">{{ 'APPLICATION.FORM3.FORM3DESCRIPTION4PARA3' | translate }}.</p>
                </div>

                <div class="col-md-12 pt-4">
                  <h4 class="para-title">{{ 'APPLICATION.FORM3.FORM3DOCUMENTHEADING' | translate }}</h4>
                  <p class="text-danger mt-1">**{{ 'APPLICATION.FORM3.FORM3DOCUMENTNOTE' | translate }}</p>
                  <div class="text-danger" *ngIf="DocumentName.length > 0">
                    <ul>
                      <li *ngFor="let doc of DocumentName" class="text-danger">{{ doc }}</li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-1"><span class="bul-num">1.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label"
                          >a) {{ 'APPLICATION.FORM3.FORM3FILE1LEVEL' | translate }}
                        </label>
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file1"
                            id="file"
                            (change)="mykad($event)"
                          />
                          <label class="custom-file-label" id="mykad1" for="customFile">{{ filename1 }}</label>
                        </div>
                        <!-- <input
                          type="file"
                          formControlName="file1"
                          id="file"
                          (change)="mykad($event)"
                          class="form-control"
                        /> -->
                        <div *ngIf="mykaderror" class="text-danger mt-1" translate>
                          {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                        </div>
                        <div
                          *ngIf="applicForm.controls['file1'].errors?.['required'] && applicForm.controls['file1'].touched"
                          class="text-danger mt-1"
                          style=""
                        >
                          {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                        </div>
                        <input type="hidden" formControlName="myKadHomeOwner" />
                      </div>
                      <!-- Charu start -->
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >b) {{ 'APPLICATION.FORM3.FORM3FILE1LEVEL2PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE1LEVEL2PART2' | translate }}</strong>
                        </label>
                        <!-- <input
                          type="file"
                          formControlName="newfile1"
                          (change)="myKadJointOwner($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile1"
                            id="file1"
                            (change)="myKadJointOwner($event)"
                          />
                          <label class="custom-file-label" id="mykad2" for="file1">{{ filename2 }}</label>
                        </div>
                        <div *ngIf="mykadjointerror" class="text-danger mt-1" translate>
                          {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                        </div>
                        <!--<small id="passwordHelpBlock" class="form-text text-muted">(for joint SSB Loan application)</small> -->
                        <input type="hidden" formControlName="myKadJointOwner" />
                      </div>
                    </div>
                  </div>
                  <!-- charu End-->
                  <div class="row">
                    <div class="col-1"><span class="bul-num">2.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          ><strong>{{ 'APPLICATION.FORM3.FORM3FILE2LEVEL1PART1' | translate }}</strong
                          >, {{ 'APPLICATION.FORM3.FORM3FILE2LEVEL1PART2' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE2LEVEL1PART3' | translate }}</strong></label
                        >
                        <!-- <input
                          type="file"
                          (change)="marriageCertificate($event)"
                          formControlName="file2"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file2"
                            id="file2"
                            (change)="marriageCertificate($event)"
                          />
                          <label class="custom-file-label" id="mykad3" for="file2">{{ filename3 }}</label>
                        </div>
                        <input type="hidden" formControlName="marriageCertificate" />
                      </div>

                      <!-- Charu Start -->
                      <div class="form-group mb-4">
                        <!-- <input
                          type="file"
                          formControlName="newfile2"
                          (change)="birthCertificate($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile2"
                            id="file3"
                            (change)="birthCertificate($event)"
                          />
                          <label class="custom-file-label" id="mykad4" for="file3">{{ filename4 }}</label>
                        </div>
                        <input type="hidden" formControlName="birthCertificate" />
                      </div>
                      <div *ngIf="marriageCertificateerror" class="text-danger mt-1 mb-3" translate>
                        {{ 'APPLICATION.FORM3.FORM3JOINTERROR' | translate }}
                      </div>
                    </div>
                  </div>
                  <!-- charuEńd -->
                  <div class="row">
                    <div class="col-1"><span class="bul-num">3.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE3LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE3LEVEL1PART2' | translate }}</strong>
                        </label>
                        <!-- <input
                          type="file"
                          (change)="landTitle($event)"
                          formControlName="file3"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file3"
                            id="file4"
                            (change)="landTitle($event)"
                          />
                          <label class="custom-file-label" id="mykad5" for="file4">{{ filename5 }}</label>
                        </div>
                        <input type="hidden" formControlName="landTileOrStrataTitle" />
                      </div>

                      <!-- Charu Start -->
                      <div class="form-group mb-4">
                        <!-- <input
                          type="file"
                          formControlName="newfile3"
                          (change)="saleAndPurchaseAgreement($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile3"
                            id="file5"
                            (change)="saleAndPurchaseAgreement($event)"
                          />
                          <label class="custom-file-label" id="mykad6" for="file5">{{ filename6 }}</label>
                        </div>
                        <input type="hidden" formControlName="saleAndPurchaseAgreement" />
                      </div>

                      <!-- 2 -->
                      <div class="form-group mb-4">
                        <!-- <input
                          type="file"
                          formControlName="newfile4"
                          (change)="deedOfAssignment($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile4"
                            id="file6"
                            (change)="deedOfAssignment($event)"
                          />
                          <label class="custom-file-label" id="mykad7" for="file6">{{ filename7 }}</label>
                        </div>
                        <input type="hidden" formControlName="deedOfAssignment" />
                      </div>
                      <div *ngIf="form3documenterror" class="text-danger mt-1 mb-3" translate>
                        {{ 'APPLICATION.FORM3.FORM3LANDERROR' | translate }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">4.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART2' | translate }}</strong>
                          {{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART3' | translate }}
                        </label>
                        <!-- <input
                          type="file"
                          (change)="akpkNo($event)"
                          formControlName="file4"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file4"
                            id="file7"
                            (change)="akpkNo($event)"
                          />
                          <label class="custom-file-label" id="mykad8" for="file7">{{ filename8 }}</label>
                        </div>
                        <input type="hidden" formControlName="akpkCertificate" />
                        <div *ngIf="akpkNoerror" class="text-danger mt-1" translate>
                          {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">5.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label">
                          {{ 'APPLICATION.FORM3.FORM3FILE3LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE5LEVEL1PART2' | translate }}</strong></label
                        >
                        <!-- <input
                          type="file"
                          (change)="receipts($event)"
                          formControlName="file5"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file5"
                            id="file9"
                            (change)="receipts($event)"
                          />
                          <label class="custom-file-label" id="mykad9" for="file9">{{ filename9 }}</label>
                        </div>
                        <input type="hidden" formControlName="receiptForQuitRent" />
                      </div>

                      <!-- Charu Start -->
                      <div class="form-group mb-4">
                        <!-- <input
                          type="file"
                          formControlName="newfile5"
                          (change)="receiptForAssesment($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile5"
                            id="file10"
                            (change)="receiptForAssesment($event)"
                          />
                          <label class="custom-file-label" id="mykad10" for="file10">{{ filename10 }}</label>
                        </div>
                        <input type="hidden" formControlName="receiptForAssesment" />
                      </div>
                      <!-- 2 -->
                      <div class="form-group mb-4">
                        <!-- <input type="file" formControlName="newfile6" class="form-control" /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile6"
                            id="file11"
                            (change)="receiptForMaintenanceFee($event)"
                          />
                          <label class="custom-file-label" id="mykad11" for="file11">{{ filename11 }}</label>
                        </div>
                        <input type="hidden" formControlName="receiptForMaintenanceFee" />
                      </div>
                      <div *ngIf="receiptserror" class="text-danger mt-1" translate>
                        {{ 'APPLICATION.FORM3.FORM4LANDERROR' | translate }}
                      </div>
                    </div>
                  </div>
                  <!-- Charu End -->
                </div>

                <div class="col-md-6">
                  <div class="row">
                    <div class="col-1"><span class="bul-num">6.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE6LEVEL1PART2' | translate }}</strong>
                          {{ 'APPLICATION.FORM3.FORM3FILE6LEVEL1PART3' | translate }}</label
                        >
                        <!-- <input
                          type="file"
                          (change)="premiumReceipts($event)"
                          formControlName="file6"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file6"
                            id="file12"
                            (change)="premiumReceipts($event)"
                          />
                          <label class="custom-file-label" id="mykad12" for="file12">{{ filename12 }}</label>
                        </div>
                        <input type="hidden" formControlName="landPremiumReceipt" />
                      </div>
                      <div *ngIf="premiumReceiptserror" class="text-danger mt-1 mb-2" translate>
                        {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">7.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE7LEVEL1PART1' | translate }}</strong></label
                        >
                        <!-- <input
                          type="file"
                          (change)="rentValuationReport($event)"
                          formControlName="file7"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file7"
                            id="file13"
                            (change)="rentValuationReport($event)"
                          />
                          <label class="custom-file-label" id="mykad13" for="file13">{{ filename13 }}</label>
                        </div>
                        <input type="hidden" formControlName="rentValuationReport" />
                      </div>
                      <div *ngIf="rentValuationReporterror" class="text-danger mt-1 mb-2" translate>
                        {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">8.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE8LEVEL1PART2' | translate }}</strong>
                          {{ 'APPLICATION.FORM3.FORM3FILE8LEVEL1PART3' | translate }}
                        </label>
                        <!-- <input
                          type="file"
                          (change)="fireAndInsurancePolicy($event)"
                          formControlName="file8"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file8"
                            id="file14"
                            (change)="fireAndInsurancePolicy($event)"
                          />
                          <label class="custom-file-label" id="mykad14" for="file14">{{ filename14 }}</label>
                        </div>
                        <input type="hidden" formControlName="fireAndHomeInsurancePolicyOrTakafulPolicy" />
                      </div>
                      <div *ngIf="fireAndInsurancePolicyerror" class="text-danger mt-1 mb-2" translate>
                        {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">9.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE4LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE9LEVEL1PART1' | translate }}</strong></label
                        >
                        <!-- <input
                          type="file"
                          (change)="ccrisReport($event)"
                          formControlName="file9"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file9"
                            id="file15"
                            (change)="ccrisReport($event)"
                          />
                          <label class="custom-file-label" id="mykad15" for="file15">{{ filename15 }}</label>
                        </div>
                        <input type="hidden" formControlName="ccrisReport" />
                        <div *ngIf="ccrisReporterror" class="text-danger mt-1" translate>
                          {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1"><span class="bul-num">10.</span></div>
                    <div class="col-11">
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE10LEVEL1PART1' | translate }}
                          <strong>{{ 'APPLICATION.FORM3.FORM3FILE10LEVEL1PART2' | translate }}</strong>
                        </label>
                        <label for="" class="control-label"
                          >a) {{ 'APPLICATION.FORM3.FORM3FILE10LEVEL2' | translate }}</label
                        >
                        <!-- <input
                          type="file"
                          (change)="lumpsumPayment($event)"
                          formControlName="file10"
                          id="file"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file10"
                            id="file16"
                            (change)="lumpsumPayment($event)"
                          />
                          <label class="custom-file-label" id="mykad16" for="file16">{{ filename16 }}</label>
                        </div>
                        <input type="hidden" formControlName="confirmationLetterFromDoctor" />
                      </div>
                      <!-- Charu start  -->
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >b) {{ 'APPLICATION.FORM3.FORM3FILE10LEVEL3' | translate }}</label
                        >
                        <!-- <input
                          type="file"
                          formControlName="newfile7"
                          (change)="fullRedemptionStatement($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile7"
                            id="file17"
                            (change)="fullRedemptionStatement($event)"
                          />
                          <label class="custom-file-label" id="mykad17" for="file17">{{ filename17 }}</label>
                        </div>
                        <input type="hidden" formControlName="fullRedemptionStatement" />
                      </div>

                      <!-- 2 -->
                      <div class="form-group mb-4">
                        <label for="" class="control-label"
                          >c) {{ 'APPLICATION.FORM3.FORM3FILE10LEVEL4' | translate }}</label
                        >
                        <!-- <input
                          type="file"
                          formControlName="newfile8"
                          (change)="renovation($event)"
                          class="form-control"
                        /> -->
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="newfile8"
                            id="file18"
                            (change)="renovation($event)"
                          />
                          <label class="custom-file-label" id="mykad18" for="file18">{{ filename18 }}</label>
                        </div>
                        <input type="hidden" formControlName="renovationQuotationOrInvoices" />
                      </div>
                      <div *ngIf="lumpsumPaymenterror" class="text-danger mt-1" translate>
                        {{ 'APPLICATION.FORM3.FORM10LUMPSUMERROR' | translate }}
                      </div>
                    </div>
                  </div>
                  <!-- Charu End -->

                  <!---->
                  <div class="row">
                    <div class="col-1"><span class="bul-num">11.</span></div>
                    <div class="col-11">
                      <div class="form-group required mb-4">
                        <label for="" class="control-label"
                          >{{ 'APPLICATION.FORM3.FORM3FILE11LEVEL1PART1' | translate }}
                          <!--strong>{{ 'APPLICATION.FORM3.FORM3FILE11LEVEL1PART1' | translate }}</strong--></label
                        >
                         <br/>
                          <div class="custom-file mb-3">
                            <a (click)="getackform()" class="link">
                              <span>{{ 'APPLICATION.FORM3.FORM3DOWNLOADACKFORM' | translate }}</span>
                            </a>
                          </div>

                             <label for="" class="control-label">                         
                          {{  'APPLICATION.FORM3.FORM3NOMINEE1' | translate}}</label>
                          
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file19"
                            id="file19"
                            (change)="nomineeAck($event)"
                          />
                          <label class="custom-file-label" id="mykad19" for="file19">{{ filename19 }}</label>
                        </div>
                        <input type="hidden" formControlName="nomineeAck" />
                        <div *ngIf="nomineeAckerror" class="text-danger mt-1" translate>
                          {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                        </div>

                          <label for="" class="label"> {{'APPLICATION.FORM3.FORM3NOMINEE2' | translate }}</label>
                        <div class="custom-file mb-3">
                          <input
                            type="file"
                            class="custom-file-input"
                            formControlName="file20"
                            id="file20"
                            (change)="nomineeAckTwo($event)"
                          />
                          <label class="custom-file-label" id="mykad20" for="file20">{{ filename20 }}</label>
                         
                        </div>
                        <input type="hidden" formControlName="nomineeAckTwo" />
                          <div *ngIf="nomineeAckerror" class="text-danger mt-1" translate>
                            {{ 'APPLICATION.FORM3.FORM3DOCUMENTERROR' | translate }}
                           
                          </div>
                          
                      </div>
                    </div>
                  </div>
                  <!---->
                </div>
              </div>

              <div class="my-3 row">
                <div class="col-md-3 text-md-left desktop-show">
                  <button
                    class="btn btn-primary btn-back mobile-wodth-100 mb-2 mb-md-0"
                    type="button"
                    (click)="prevStep()"
                    [disabled]="isLoading || isLoading2"
                  >
                    <span translate>{{ 'APPLICATION.FORM3.FORM3BUTTON1' | translate }}</span>
                  </button>
                </div>
                <div class="col-md-9 text-md-right text-left">
                  <button
                    class="btn btn-primary btn-custom mr-3 mobile-wodth-100 mb-2 mb-md-0"
                    (click)="saveForm()"
                    type="button"
                    [disabled]="isLoading || isLoading2"
                  >
                    <i class="fas fa-cog fa-spin" [hidden]="!isLoading2"></i>
                    <span translate>{{ 'APPLICATION.FORM3.FORM3BUTTON3' | translate }}</span>
                  </button>
                  <button
                    class="btn btn-primary btn-custom mobile-wodth-100 mb-2 mb-md-0"
                    (click)="submitForm()"
                    type="submit"
                    [disabled]="isLoading || isLoading2"
                  >
                    <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                    <span translate>{{ 'APPLICATION.FORM3.FORM3BUTTON2' | translate }}</span>
                  </button>
                </div>
                <div class="col-md-3 text-md-left mobile-show mt-3">
                  <button
                    class="btn btn-primary btn-back mobile-wodth-100 mb-2 mb-md-0"
                    type="button"
                    (click)="prevStep()"
                    [disabled]="isLoading || isLoading2"
                  >
                    <span translate>{{ 'APPLICATION.FORM3.FORM3BUTTON1' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
