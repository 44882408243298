import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { CustomValidators } from '../../providers/CustomValidators';
import Swal from 'sweetalert2';
import { AdminService } from './admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  StaffList: any = [];
  closeResult: string = '';
  staffList: any = [];
  allStaffList: any = [];
  searchText: any;
  filteredUsers: any;
  filterBy: any = [];
  allchecked: boolean = false;
  allnotpresent: boolean = false;
  p: number = 1;
  page: number = 1;
  filterModel = {
    email: '',
    pageNo: '0',
    pageSize: 1000,
  };
  rolesModel = {
    userId:'',
    role: '',
    email:'',
    roleid:'',
    description: '',
  };
  
  

  constructor(
    private translateService: TranslateService,
    private adminService: AdminService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getStaffData();
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  open(content: any, email: any) {
    this.rolesModel.email = email;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log(this.closeResult)
        // this.resetnForm.markAsPristine();
        // this.resetnForm.reset();
      },
      (reason) => {
        this.closeResult = `Dismissed `;
        console.log(this.closeResult);
      }
    );
  }

  getStaffData = async () => {
    var params = {
      pageNo: '0',
      page_size: 10,
    };
    await this.adminService.getStaffData().subscribe(
      (responce) => {
        console.log(responce);
        this.StaffList = responce;
        this.allStaffList = [];
        this.StaffList.forEach((item: any) => {
          this.allStaffList.push(item.email);
          this.rolesModel.email = item.email;
        });
        
      },
      (err) => {
        console.log(err);
      }
    );
  };

  


  onSearch = async ()=> {
    await this.adminService.search(this.filterModel).subscribe(
      (response: any) => {
        console.log(response);
        this.StaffList = response['AccountRequest'] ?? [];
        this.staffList = [];
        this.p = 1;
        this.page = 1;
        this.StaffList.forEach((item: any) =>{
          this.allStaffList.push(item.email);
        });
      },
      (error) => {
        console.log(error);
      }
    )
  }

  roleSubmit = async () => {
    await this.adminService.updateRoles(this.rolesModel).subscribe(
      (res: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Role Changed Successfully',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        }).then((result) => {
          this.getStaffData();
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  onTableDataChange(event: any) {
    this.page = event;
    //this._onSearch();
  }

  reset() {
    this.filterModel = {
      email: '',
      pageNo: '0',
      pageSize: 1000,
    };
    this.getStaffData();
  };

}
