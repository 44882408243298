import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CredentialsService } from './credentials.service';
// import * as FileSaver from 'file-saver';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpHeaderOptions: { headers: HttpHeaders };
  applicationheader: { headers: HttpHeaders };
  httpHeaders: any;
  auth_token = localStorage.getItem('bearer');
  // auth_token =
  //   'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzaGFoYmF6ei5hcnFhbUBnbWFpbC5jb20iLCJleHAiOjE2NjM4NjIzOTksImlhdCI6MTY2MzgzNjg1Mn0.fKxL_HDrHR3hQ4kN5Aiof-b3CE91BoBRO2li3CEW6Bs';
  constructor(
    private credentialsService: CredentialsService, 
    private _http: HttpClient
    ) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    this.httpHeaderOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.auth_token + '',
      }),
    };
    this.applicationheader = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.auth_token + '',
      }),
    };
  }

  getUserData(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.get<any[]>('/../ssb/application/get/all/customers', this.httpHeaderOptions);
  }

  getapplication(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.get<any[]>(
      '/../ssb/application/get/all?userId=' + this.credentialsService.credentials?.email,
      this.httpHeaderOptions
    );
  }


  // 

  submit_akpk_one(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>('/../ssb/application/create', body, this.httpHeaderOptions);
  }

// 




  statuschange(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>('/../ssb/application/remark', body, this.httpHeaderOptions);
  }
  submit_akpk_two(body: any, apkid: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>(
      '/../ssb/application/document/upload?applicationId=' + apkid + '',
      body,
      this.applicationheader
    );
  }
  getakpkdocument() {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    // this._http.get('/api/download-attachment/akpk.docx', { responseType: 'blob' }).subscribe((response: any) => {
            this._http.get('/api/download-attachment/akpk.pdf', { responseType: 'blob' }).subscribe((response: any) => {

      // const newBlob = new Blob([response], { type: 'application/msword' });
            const newBlob = new Blob([response], { type: 'application/pdf' });

      const downloadURL = URL.createObjectURL(newBlob);
      const anchor = document.createElement('a');
      // anchor.download = 'AKPK Checklist.docx';
            anchor.download = 'AKPK Checklist.pdf';

      anchor.href = downloadURL;
      anchor.click();
    });
  }


  getackFormEN() {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    this._http.get('/api/download-attachment/ACKForm(EN).pdf', { responseType: 'blob' }).subscribe((response: any) => {
      const newBlob = new Blob([response], { type: 'application/pdf' });
      const downloadURL = URL.createObjectURL(newBlob);
      const anchor = document.createElement('a');
      anchor.download = 'ACKNOWLEDGEMENT FORM (EN).pdf';
      anchor.href = downloadURL;
      anchor.click();
    });
  }

  getackFormBM() {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    this._http.get('/api/download-attachment/ACKForm(BM).pdf', { responseType: 'blob' }).subscribe((response: any) => {
      const newBlob = new Blob([response], { type: 'application/pdf' });
      const downloadURL = URL.createObjectURL(newBlob);
      const anchor = document.createElement('a');
      anchor.download = 'ACKNOWLEDGEMENT FORM (BM).pdf';
      anchor.href = downloadURL;
      anchor.click();
    });
  }

  getexcellist(applicationId: any) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);

    let params = new HttpParams().append('applicationIds', applicationId);

    this._http
      .get('/../ssb/file/generateCSVList', { params: params, headers: headers, responseType: 'blob' as 'json' })
      .subscribe((response: any) => {
        const newBlob = new Blob([response], {
          type: 'text/csv;charset=utf-8',
        });
        const downloadURL = URL.createObjectURL(newBlob);
        const anchor = document.createElement('a');
        anchor.download = 'application.csv';
        anchor.href = downloadURL;
        anchor.click();
      });
  }
  getUserDocumentList(applicationId: any): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);

    let params = new HttpParams().append('applicationId', applicationId);
    return this._http.get<any[]>('/../ssb/application/get/documents/list', { params: params, headers: headers });
  }
  getcsvmultiple(applicationId: any) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);
    let httpParams = new HttpParams();

    for (let i = 0; i < applicationId.length; i++) {
      httpParams = httpParams.append('applicationIds', applicationId[i]);
    }
    console.log(applicationId.length);

    this._http
      .get('/../ssb/file/generateCSVList', { params: httpParams, headers: headers, responseType: 'blob' as 'json' })
      .subscribe((response: any) => {
        const newBlob = new Blob([response], {
          type: 'text/csv;charset=utf-8',
        });
        const downloadURL = URL.createObjectURL(newBlob);
        const anchor = document.createElement('a');
        if (applicationId.length < 2) {
          anchor.download = applicationId[0] + '.csv';
        } else {
          anchor.download = 'ssb-customer-application.csv';
        }
        anchor.href = downloadURL;
        anchor.click();
      });
  }
  genrateexcelmultiple(applicationId: any) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);
    let httpParams = new HttpParams();

    for (let i = 0; i < applicationId.length; i++) {
      httpParams = httpParams.append('applicationIds', applicationId[i]);
    }
    console.log(applicationId.length);

    this._http
      .get('/../ssb/file/generateExcel', { params: httpParams, headers: headers, responseType: 'blob' as 'json' })
      .subscribe((response: any) => {
        const newBlob = new Blob([response], {
          type: 'application/vnd.ms-excel',
        });
        const downloadURL = URL.createObjectURL(newBlob);
        const anchor = document.createElement('a');
        if (applicationId.length < 2) {
          anchor.download = applicationId[0] + '.xlsx';
        } else {
          anchor.download = 'ssb-customer-application.xlsx';
        }
        anchor.href = downloadURL;
        anchor.click();
      });
  }
  // http://35.247.151.211:8080/ssb/application/get/all/customers

  _searchUser = (_filterModel: any) => {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);
    let httpParams = new HttpParams();

    if (_filterModel.applicationno != '') {
      httpParams = httpParams.append('applicationId', _filterModel.applicationno);
    }
    if (_filterModel.date != '') {
      httpParams = httpParams.append('createdDate', _filterModel.date);
    }
    if (_filterModel.cemail != '') {
      httpParams = httpParams.append('customerRegisteredEmailId', _filterModel.cemail);
    }
    if (_filterModel.email != '') {
      httpParams = httpParams.append('email', _filterModel.email);
    }
    if (_filterModel.status != '') {
      httpParams = httpParams.append('orderStatus', _filterModel.status);
    }
    if (_filterModel.pageNo != '') {
      httpParams = httpParams.append('pageNumber', _filterModel.pageNo);
    }
    if (_filterModel.pageSize != '') {
      httpParams = httpParams.append('pageSize', _filterModel.pageSize);
    }
    if (_filterModel.postcode != '') {
      httpParams = httpParams.append('postCode', _filterModel.postcode);
    }
    return this._http.get(`/../ssb/search/application-list`, { params: httpParams, headers: headers });
  };

  getakpkdocumentmy() {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    // this._http.get('/api/download-attachment/akpk(BM).docx', { responseType: 'blob' }).subscribe((response: any) => {
            this._http.get('/api/download-attachment/akpk(BM).pdf', { responseType: 'blob' }).subscribe((response: any) => {

      // const newBlob = new Blob([response], { type: 'application/msword' });
            const newBlob = new Blob([response], { type: 'application/pdf' });

      const downloadURL = URL.createObjectURL(newBlob);
      const anchor = document.createElement('a');
      // anchor.download = 'AKPK Checklist.docx';
            anchor.download = 'AKPK Senarai Semak.pdf';

      anchor.href = downloadURL;
      anchor.click();
    });
  }
  downloaddocument(documentname: any, applicationId: any) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);
    let httpParams = new HttpParams();

    httpParams = httpParams.append('applicationId', applicationId);
    httpParams = httpParams.append('documentType', documentname);

    this._http
      .get('/../ssb/application/document/download', {
        params: httpParams,
        headers: headers,
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe((response: any) => {
        const newBlob = new Blob([response.body as Blob], {
          type: response.headers.get('content-type'),
        });
        //FileSaver.saveAs(newBlob, response.headers.get('filename'));
        const downloadURL = URL.createObjectURL(newBlob);
        const anchor = document.createElement('a');

        anchor.download = response.headers.get('filename');

        anchor.href = downloadURL;
        anchor.click();
      });
  }
  validate(): Observable<any> {
    const body = localStorage.getItem('bearer');
    // const httpOptions = {
    //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    // };
    return this._http.post<any[]>('/api/validate-token', body);
  }
}
