import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import Swal from 'sweetalert2';
import { NationalityValidators } from 'src/providers/NationalityValidators';
import { EligibilityCheckService } from '../eligibility--check.service';
import { browserRefresh } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-eligibility-check',
  templateUrl: './eligibility-check.component.html',
  styleUrls: ['./eligibility-check.component.scss'],
})
export class EligibilityCheckComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  ageerror = false;
  ownererror = -1;
  leaseerror = false;
  propertyerror = false;
  leaseexpireerror = false;
  propertyfreehold = false;
  personlimiterror = -1;
  personrelationerror = -1;
  jointerror = -1;
  ownerageerror = false;
  outstandingbalerror = false;
  outstdbalerr = false;
  outstdbalerr2 = false;
  propertyencumerr = false;
  eligibilityDetails: any;
  applicForm!: FormGroup;
  nationality: any = localStorage.getItem('nationality');
  enablediv: any = ['div1'];
  postcodeerror = false;
  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private EligibilityCheck: EligibilityCheckService
  ) {
    this.createForm();
    this.setUserCategoryValidators();
    this.setUserleaseValidators();
    this.setSoleOwnerValidators();
    this.setPropertyOwnerValidators();
    this.setOwnerSpouseValidators();
    this.setOutstandingValidators();
    this.setMortgageValidators();
    //this.divenable();
    // this.setRemainingOutstdValidators();
  }

  ngOnInit() {
    // this.isLoading = true;
    // this.quoteService
    //   .getRandomQuote({ category: 'dev' })
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((quote: string) => {
    //     this.quote = quote;
    //     console.log(this.quote);
    //   });
    if (browserRefresh == true) {
      this.router.navigate(['/']);
    }
  }
  switch(language: any) {
    /** For Malashiyan */
    console.log(language);
    localStorage.setItem('language', language);
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    location.reload();
  }
  private createForm() {
    this.applicForm = this.formBuilder.group({
      joint: ['', Validators.required],
      salutation: [''],
      applicant_name: [''],
      nric_no: [''],
      res_address: [''],
      postcode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      email: [''],
      res_tel: [''],
      hp_tel: [''],
      dob: ['', Validators.required],
      race: [''],
      nationality: ['', [Validators.required, NationalityValidators]],
      sex: [''],
      marital_status: [''],
      no_dep: [''],
      no_dep1: [''],
      no_dep2: [''],
      no_dep3: [''],
      no_dep4: [''],
      no_dep5: [''],
      present_house: ['', Validators.required],
      occupation: [''],
      name_of_employer: [''],
      address_of_employer: [''],
      postcode_of_employer: [''],
      purpose_of_ssb: [''],
      payout_option: [''],
      lease_expiry_date: [''],
      usage_of_lumpsum: [''],
      cost_expenses: [''],
      freehold: ['', Validators.required],
      property_encumbered: ['', Validators.required],
      outstanding_balns: ['', Validators.required],
      sole_owner: ['', Validators.required],
      personlimit: [''],
      personrelation: [''],
      owneragelimit: [''],
      outstandingbal: [''],
      outstandingbal2: [''],
    });
  }

  setUserCategoryValidators() {
    const institutionControl = this.applicForm.get('usage_of_lumpsum');
    this.applicForm.get('payout_option')?.valueChanges.subscribe((userCategory) => {
      if (userCategory === 'lumpsum') {
        this.applicForm.controls['usage_of_lumpsum'].setValidators([Validators.required]);
      } else {
        institutionControl?.setValidators(null);
      }
      institutionControl?.updateValueAndValidity();
    });
  }

  setUserleaseValidators() {
    this.applicForm.get('freehold')?.valueChanges.subscribe((userLease) => {
      if (userLease === '0') {
        this.applicForm.controls['lease_expiry_date'].setValidators([Validators.required]);
      } else {
        this.applicForm?.setValidators(null);
      }
      this.applicForm?.updateValueAndValidity();
    });
  }

  setSoleOwnerValidators() {
    this.applicForm.get('sole_owner')?.valueChanges.subscribe((userOwner) => {
      if (userOwner === '0') {
        this.applicForm.controls['personlimit'].setValidators([Validators.required]);
      } else {
        this.applicForm?.setValidators(null);
      }
      this.applicForm?.updateValueAndValidity();
    });
  }

  setPropertyOwnerValidators() {
    this.applicForm.get('personlimit')?.valueChanges.subscribe((userCategory) => {
      if (userCategory === '0') {
        this.applicForm.controls['personrelation'].setValidators([Validators.required]);
      } else {
        this.applicForm?.setValidators(null);
      }
      this.applicForm?.updateValueAndValidity();
    });
  }

  setOwnerSpouseValidators() {
    this.applicForm.get('personrelation')?.valueChanges.subscribe((userCategories) => {
      if (userCategories === '0') {
        this.applicForm.controls['owneragelimit'].setValidators([Validators.required]);
      } else {
        this.applicForm?.setValidators(null);
      }
      this.applicForm?.updateValueAndValidity();
    });
  }

  setMortgageValidators() {
    this.applicForm.get('outstanding_balns')?.valueChanges.subscribe((userCategories) => {
      if (userCategories === '0') {
        this.applicForm.controls['outstandingbal'].setValidators([Validators.required]);
      } else {
        this.applicForm?.setValidators(null);
      }
      this.applicForm?.updateValueAndValidity();
    });
  }

  setOutstandingValidators() {
    this.applicForm.get('outstandingbal')?.valueChanges.subscribe((userCategories) => {
      if (userCategories === '0') {
        this.applicForm.controls['outstandingbal2'].setValidators([Validators.required]);
      } else {
        this.applicForm?.setValidators(null);
      }
      this.applicForm?.updateValueAndValidity();
    });
  }

  // setRemainingOutstdValidators() {
  //   this.applicForm.get('outstandingbal2')?.valueChanges.subscribe((userCategories) => {
  //     if (userCategories === '0') {
  //       this.applicForm.controls['owneragelimit'].setValidators([Validators.required]);
  //     } else {
  //       this.applicForm?.setValidators(null);
  //     }
  //     this.applicForm?.updateValueAndValidity();
  //   });
  // }

  apkpk_one() {
    if (this.applicForm.valid) {
      // localStorage.setItem('applcation_type', this.applicForm.value.joint);
      // this.router.navigate(['/application-part-two']);
    } else {
      // localStorage.setItem('applcation_type', this.applicForm.value.joint);
      // this.router.navigate(['/application-part-two']);
      this.markFormGroupTouched(this.applicForm);
    }
  }
  dateIsValid(date: any) {
    return !isNaN(date) && date instanceof Date;
  }
  check_date(e: any) {
    // const convertAge = new Date(e.target.value);
    // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    // var ages = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    // console.log("ages",ages);
    var today: any = new Date();
    var birthDate = new Date(e.target.value);
    /*if (Object.prototype.toString.call(birthDate) != '[object Date]' || !this.dateIsValid(birthDate)) {
      this.ageerror = true;
    } else {
      this.ageerror = false;
      this.divenable();
    }*/
    var ages = Math.floor((today - new Date(birthDate).getTime()) / 3.15576e10);
    console.log('ages', ages);
    if (ages < 55 || Object.prototype.toString.call(birthDate) != '[object Date]' || !this.dateIsValid(birthDate)) {
      this.ageerror = true;
    } else if (ages > 55) {
      this.ageerror = false;
      this.divenable();
    } else if (ages == 55) {
      this.ageerror = false;
      this.divenable();
    }
    // else {
    //   this.ageerror = false;
    //   this.divenable();
    // }
  }

  check_owner(owner: any) {
    if (owner == '1') {
      this.ownererror = 0;
      this.applicForm.controls['sole_owner'].setValue('true');
    } else {
      this.ownererror = 1;
      this.personlimiterror = -1;
      this.applicForm.controls['sole_owner'].setValue('false');
    }
  }

  check_lease(lease: any) {
    if (lease == '1') {
      this.leaseerror = false;
      this.applicForm.controls['freehold'].setValue('true');
    } else {
      this.leaseerror = true;
      this.applicForm.controls['freehold'].setValue('false');
    }
  }

  check_property(property: any) {
    if (property == '1') {
      this.propertyerror = false;
      this.applicForm.controls['present_house'].setValue('true');
    } else {
      this.applicForm.controls['present_house'].setValue('false');
      this.propertyerror = true;
    }
  }

  check_leaseexpire(leaseexpire: any) {
    if (leaseexpire == '1') {
      this.leaseexpireerror = false;
      this.applicForm.controls['lease_expiry_date'].setValue('true');
    } else {
      this.leaseexpireerror = true;
      this.applicForm.controls['lease_expiry_date'].setValue('false');
    }
  }

  check_personlimit(personlimit: any) {
    if (personlimit == '1') {
      this.personlimiterror = 1;
      this.personrelationerror = -1;
      this.applicForm.controls['owneragelimit'].reset();
      this.ownerageerror = false;
      this.enablediv = this._resetDivArray('div7');
      this.applicForm.controls['personrelation'].reset();
      this.applicForm.controls['joint'].reset();
      this.applicForm.controls['outstanding_balns'].reset();
      this.applicForm.controls['outstandingbal2'].reset();
    } else {
      this.personlimiterror = 0;
    }
  }

  check_personrelation(personrelation: any) {
    if (personrelation == '1') {
      this.personrelationerror = 1;
      this.ownerageerror = false;
      this.enablediv = this._resetDivArray('div7');
      this.applicForm.controls['joint'].reset();

      this.applicForm.controls['outstanding_balns'].reset();
      this.applicForm.controls['outstandingbal2'].reset();
      this.applicForm.controls['owneragelimit'].reset();
    } else {
      this.personrelationerror = 0;
    }
  }

  check_joint(jointaccount: any) {
    if (jointaccount == '1') {
      this.jointerror = 1;
      this.applicForm.controls['joint'].setValue('true');
    } else {
      this.jointerror = 0;
      this.applicForm.controls['joint'].setValue('false');
    }
  }

  check_ownerage(ownerage: any) {
    if (ownerage == '1') {
      this.ownerageerror = true;
      this.applicForm.controls['owneragelimit'].setValue('false');
    } else {
      this.ownerageerror = false;
      this.applicForm.controls['owneragelimit'].setValue('true');
    }
  }

  check_outstdbalerr(ostbalerr: any) {
    if (ostbalerr == '1') {
      this.outstdbalerr = false;
      this.applicForm.controls['outstandingbal'].setValue('true');
      this.enablediv.push('div9');
      this.applicForm.controls['outstandingbal2'].reset();
    } else {
      this.outstdbalerr = true;
      this.applicForm.controls['outstandingbal'].setValue('false');
      this.enablediv = this._resetDivArray('div9');
      this.applicForm.controls['outstandingbal2'].reset();
    }
  }

  _resetTab8Child = () => {
    this.applicForm.controls['outstandingbal'].reset();
    this.outstdbalerr = false;
    this.outstdbalerr2 = false;
    this.applicForm.controls['outstandingbal2'].reset();
    this.applicForm.controls['property_encumbered'].reset();
    this.enablediv = this._resetDivArray('div9');
  };

  check_outstdbalerror(outstandingbal: any) {
    if (outstandingbal == '1') {
      this.outstandingbalerror = false;
      this.applicForm.controls['outstanding_balns'].setValue('true');
    } else {
      this.outstandingbalerror = true;
      this.applicForm.controls['outstanding_balns'].setValue('false');
    }
  }

  check_outstdbalerr2(outstandingbal2: any) {
    if (outstandingbal2 == '1') {
      this.outstdbalerr2 = false;
      this.applicForm.controls['outstandingbal2'].setValue('true');
      this.enablediv.push('div9');
    } else {
      this.outstdbalerr2 = true;
      this.applicForm.controls['outstandingbal2'].setValue('false');
    }
  }

  outstandingbal2Enable = () => {
    if (this.applicForm.value.outstandingbal2 == 'true') {
      this.enablediv.push('div9');
    } else if (this.applicForm.value.outstandingbal2 == 'false') {
      this.enablediv = this._resetDivArray('div9');
      this.applicForm.controls['property_encumbered'].reset();
    }
  };

  check_propertyencumerr(propertyencumer: any) {
    if (propertyencumer == '1') {
      this.propertyencumerr = false;
      this.applicForm.controls['property_encumbered'].setValue('true');
    } else {
      this.propertyencumerr = true;
      this.applicForm.controls['property_encumbered'].setValue('false');
    }
  }

  submitEligibility() {
    console.log(this.applicForm.value);
    if (this.applicForm.valid) {
      if (this.postcodeerror == false) {
        var body = [
          {
            customerRemark: this.applicForm.value.nationality,
            eligibilityCriteriaDescription: 'Are you Malaysian?',
            eligibilityCriteriaId: 1,
            status: null,
          },
          {
            customerRemark: this.applicForm.value.dob,
            eligibilityCriteriaDescription: 'Date of Birth',
            eligibilityCriteriaId: 2,
            status: null,
          },
          {
            customerRemark: this.applicForm.value.postcode,
            eligibilityCriteriaDescription: 'Please provide postal code of your residential property',
            eligibilityCriteriaId: 3,
            status: null,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Is the property your primary place of residence?',
            eligibilityCriteriaId: 4,
            status: this.applicForm.value.present_house,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Freehold or leasehold?',
            eligibilityCriteriaId: 5,
            status: this.applicForm.value.freehold,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'What is the remaining lease tenure?',
            eligibilityCriteriaId: 6,
            status: this.applicForm.value.lease_expiry_date,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Are you the sole owner of the property?',
            eligibilityCriteriaId: 7,
            status: this.applicForm.value.sole_owner,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Is the property owned by 2 persons?',
            eligibilityCriteriaId: 8,
            status: this.applicForm.value.personlimit,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Is the other owner your spouse, parent, child, sister or brother?',
            eligibilityCriteriaId: 9,
            status: this.applicForm.value.personrelation,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Is the other owner aged 55 years and above?',
            eligibilityCriteriaId: 10,
            status: this.applicForm.value.owneragelimit,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Do you or/and the joint owner',
            eligibilityCriteriaId: 11,
            status: this.applicForm.value.joint,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription:
              'Is your property mortgage free i.e., no home loan/financing remained outstanding?',
            eligibilityCriteriaId: 12,
            status: this.applicForm.value.outstanding_balns,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription:
              'Does the current outstanding loan/financing amount lower than the Maximum Lumpsum Amount (refer to Reverse Mortgage Calculator @ https://ssb.cagamas.com.my/reverse-mortgage-calculator)?',
            eligibilityCriteriaId: 13,
            status: this.applicForm.value.outstandingbal,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription:
              'Do you intend to settle all remaining outstanding loan/financing amount by yourself to redeem your property from bank/current financer?',
            eligibilityCriteriaId: 14,
            status: this.applicForm.value.outstandingbal2,
          },
          {
            customerRemark: null,
            eligibilityCriteriaDescription: 'Is your property free from encumbrances?',
            eligibilityCriteriaId: 15,
            status: this.applicForm.value.property_encumbered,
          },
        ];
        localStorage.setItem('eligibilityCreteria', JSON.stringify(body));
        this.router.navigate(['/registration']);
      }

      // this.EligibilityCheck.eligibilityCreteria(localStorage.getItem('RegisteredEmail'), body).subscribe((data) => {

      //   let eligibilityDetails = data.eligibilityDetails;
      //   localStorage.setItem('eligibilityDetails', eligibilityDetails);
      //   console.log(data);
      //   if (data.error == '200') {
      //     Swal.fire({ icon: 'success', title: 'Success...', text: 'Eligibility Creteria Matched. Please Register' });
      //     this.router.navigate(['/registration']);
      //   }
      // },
      // (error) => {
      //   console.log(error);
      //   Swal.fire({ icon: 'error', title: 'Oops...', text: 'Please Try again later!' });
      // }
      // );
    } else {
      // alert("Please fill all required fields");
    }
  }
  divenable() {
    // this.applicForm.valueChanges.subscribe((userCategory) => {

    if (this.applicForm.value.nationality == 'Malaysian') {
      this.enablediv.push('div2');
    } else if (this.applicForm.value.nationality == 'Non-Malaysian') {
      this.enablediv = [];
      this.enablediv.push('div1');
      this._resetForm();
    }
    if (this.applicForm.value.dob) {
      this.enablediv.push('div3');
    }

    if (this.applicForm.value.postcode && !this.applicForm.controls['postcode'].errors?.['maxlength']) {
      this.enablediv.push('div4');
    } else {
      this.enablediv = this._resetDivArray('div4');
      this._resetDiv4();
    }
    if (this.applicForm.value.present_house == 'true') {
      this.enablediv.push('div5');
    } else if (this.applicForm.value.present_house == 'false') {
      this.enablediv = this._resetDivArray('div5');
      this._resetDiv5();
    }
    if (this.applicForm.value.freehold == 'true') {
      this.enablediv.push('div6');
    } else if (
      (this.applicForm.value.freehold == 'false' && this.applicForm.value.lease_expiry_date == 'true') ||
      this.applicForm.value.lease_expiry_date == 'false'
    ) {
      this.enablediv.push('div6');
    } else if (this.applicForm.value.freehold == 'false') {
      this.enablediv = this._resetDivArray('div6');
      this._resetDiv6();
    }
    //******************** */
    if (this.applicForm.value.sole_owner == 'true') {
      this.enablediv.push('div7');
    }

    //************************* */
    if (this.applicForm.value.owneragelimit == 'true') {
      this.enablediv.push('div7');
    } else if (this.applicForm.value.owneragelimit == 'false') {
      this.enablediv = this._resetDivArray('div7');
      this.applicForm.controls['joint'].reset();
      this.applicForm.controls['outstanding_balns'].reset();
      this.applicForm.controls['outstandingbal2'].reset();
      this.outstdbalerr = false;
      this.outstdbalerr2 = false;
      this.jointerror = -1;
    }

    if (this.applicForm.value.joint == 'true') {
      this.enablediv.push('div8');
    } else {
      this.enablediv = this._resetDivArray('div8');
      this.applicForm.controls['outstanding_balns'].reset();
      this.applicForm.controls['outstandingbal2'].reset();
      this.outstdbalerr = false;
      this.outstdbalerr2 = false;
      this._resetTab8Child();
    }

    if (this.applicForm.value.outstanding_balns == 'true') {
      this.enablediv.push('div9');
    } else if (this.applicForm.value.outstanding_balns == 'false') {
      this.enablediv = this._resetDivArray('div9');
      this.applicForm.controls['outstandingbal2'].reset();
      this.outstdbalerr2 = false;
    }

    if (this.applicForm.value.joint == 'true') {
      this.enablediv.push('div8');
    } else {
      this.enablediv = this._resetDivArray('div8');
      this.applicForm.controls['outstanding_balns'].reset();
      this.applicForm.controls['outstandingbal2'].reset();
      this.outstdbalerr = false;
      this.outstdbalerr2 = false;
      this._resetTab8Child();
    }

    if (this.applicForm.value.outstanding_balns == 'true') {
      this.enablediv.push('div9');
    } else if (this.applicForm.value.outstanding_balns == 'false') {
      this.enablediv = this._resetDivArray('div9');
      this.applicForm.controls['outstandingbal2'].reset();
      this.outstdbalerr2 = false;
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
  _resetForm = () => {
    this.applicForm.controls['dob'].reset();
    this.ageerror = false;
    this.applicForm.controls['postcode'].reset();
    this.applicForm.controls['present_house'].reset();
    this.applicForm.controls['freehold'].reset();
    this.applicForm.controls['lease_expiry_date'].reset();
    this.applicForm.controls['sole_owner'].reset();
    this.applicForm.controls['personrelation'].reset();
    this.applicForm.controls['personlimit'].reset();
    this.applicForm.controls['owneragelimit'].reset();
    this.applicForm.controls['joint'].reset();
    this.applicForm.controls['outstanding_balns'].reset();
    this.applicForm.controls['outstandingbal2'].reset();
  };
  _resetDiv4() {
    this.applicForm.controls['present_house'].reset();
    this.applicForm.controls['freehold'].reset();
    this.applicForm.controls['lease_expiry_date'].reset();
    this.applicForm.controls['sole_owner'].reset();
    this.applicForm.controls['personrelation'].reset();
    this.applicForm.controls['personlimit'].reset();
    this.applicForm.controls['owneragelimit'].reset();
    this.applicForm.controls['joint'].reset();
    this.applicForm.controls['outstanding_balns'].reset();
    this.applicForm.controls['outstandingbal2'].reset();
  }
  _resetDiv5() {
    this.applicForm.controls['freehold'].reset();
    this.applicForm.controls['lease_expiry_date'].reset();
    this.applicForm.controls['sole_owner'].reset();
    this.applicForm.controls['personrelation'].reset();
    this.applicForm.controls['personlimit'].reset();
    this.applicForm.controls['owneragelimit'].reset();
    this.applicForm.controls['joint'].reset();
    this.applicForm.controls['outstanding_balns'].reset();
    this.applicForm.controls['outstandingbal2'].reset();
  }
  _reseDiv5Child = () => {
    this.applicForm.controls['lease_expiry_date'].reset();
    this.leaseexpireerror = false;
  };
  _resetDiv6() {
    this.applicForm.controls['sole_owner'].reset();
    this.applicForm.controls['personrelation'].reset();
    this.applicForm.controls['personlimit'].reset();
    this.applicForm.controls['owneragelimit'].reset();
    this.applicForm.controls['joint'].reset();
    this.applicForm.controls['outstanding_balns'].reset();
    this.applicForm.controls['outstandingbal2'].reset();
  }
  // _reseDiv5Child=()=>{
  //   this.applicForm.controls['lease_expiry_date'].reset();
  //   this.leaseexpireerror=false;
  // }
  // _resetDiv6(){

  //   this.applicForm.controls['sole_owner'].reset();
  //   this.applicForm.controls['personrelation'].reset();
  //   this.applicForm.controls['personlimit'].reset();
  //   this.applicForm.controls['owneragelimit'].reset();
  //   this.applicForm.controls['joint'].reset();
  //   this.applicForm.controls['outstanding_balns'].reset();
  //   this.applicForm.controls['outstandingbal2'].reset();
  // }
  _resetDivArray = (_divArrayItem: any) => {
    return this.enablediv
      .filter((_itm: any) => {
        return _itm !== _divArrayItem;
      })
      .filter((item: any, i: any, ar: string | any[]) => ar.indexOf(item) === i);
  };
  _resetTAb6Child = () => {
    this.ownererror = 1;
    this.personlimiterror = -1;
    this.personrelationerror = -1;
    this.applicForm.controls['personlimit'].reset();
    this.applicForm.controls['personrelation'].reset();
    this.applicForm.controls['owneragelimit'].reset();
    this.applicForm.controls['joint'].reset();
    this.applicForm.controls['outstanding_balns'].reset();
    this.applicForm.controls['outstandingbal2'].reset();
    this.enablediv = this._resetDivArray('div7');
    this.outstdbalerr = false;
    this.outstdbalerr2 = false;
    this.jointerror = -1;
    this._resetTab8Child();
  };

  post_code_validate() {
    this.EligibilityCheck.postcode(this.applicForm.value.postcode).subscribe(
      (data) => {},
      (error) => {
        if (error.status == 200) {
          this.postcodeerror = false;
        } else {
          this.postcodeerror = true;
        }
      }
    );
  }
  reset = () => {
    this.applicForm.reset();
    this.applicForm.controls['nationality'].setErrors(null);
  };
}
