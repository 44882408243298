<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/css/all.min.css"
  integrity="sha512-3M00D/rn8n+2ZVXBO9Hib0GKNpkm8MSUU/e2VNthDyBYxKWG+BftNYYcuEjXlyrSO637tidzMBXfE7sQm0INUg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>
<style>
  .ngx-pagination {
    margin-bottom: 2rem;
    padding: 0;
  }
</style>
<section class="main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="heading mt-3">User Management System</h2>
      </div>
      <div class="col-md-12">
        <form class="filter-area my-3 filter-mobile-100">
          <div class="form-row">
             <div class="col-auto mb-3 mb-lg-0">
              <input
                type="text"
                name="email"
                class="form-control"
                [(ngModel)]="filterModel.email"
                placeholder="Email"
              />
            </div>
            
            
            
            
            <div class="col-auto mb-3 mb-lg-0">
              <button  type="submit" class="btn btn-primary mb-2 btn-filter-custom" (click)="onSearch()">Search</button>
            </div>
            <div class="col-auto mb-3 mb-lg-0">
              <button type="button"class="btn btn-primary mb-2 btn-filter-custom" (click)="reset()">Reset</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12">
        <div class="table-responsive css-table">
          <table class="table table-striped custom-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Email</th>
                <th>Name</th>
                <th>Role</th>
                <th>Updated Date</th>


                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
              let item of StaffList | paginate: { itemsPerPage: 10, currentPage: page, totalItems: StaffList.length };
              let i = index
            ">
            <td data-title="No">{{ (page - 1) * 10 + i + 1 }}</td>
              <td data-title="Email">{{item.email}}</td>
              <td data-title="Name">{{item.fullname}}</td>
              <td data-title="Role">
                <span class="bg-warning text-white p-2 py-1 rounded"
                (click)="open(mymodal, item.email)"
                style="cursor: pointer; font-size: 14px">
                {{item.role.description}}
              </span>
            </td>
              <td data-title="updatedDate">{{item.lastModifiedDate | date}}</td>
              </tr>
              
            </tbody>
          </table>
          <pagination-controls (pageChange)="page = $event" style="text-align: left"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #mymodal let-modal>
  <div class="p-4">
    <div class="card-body">
      <h4 class="card-title text-left mb-4">
        <!-- <i class="far fa-3x fa-user-circle text-muted"></i> -->
        Change Role
      </h4>
      <!-- <form (ngSubmit)="remarkssubmit()"  novalidate> -->
      <div class="my-3">
        <label class="d-block mb-3">
          <select class="form-control" name="role" [(ngModel)]="rolesModel.roleid">
            <option value="" disabled>Role</option>
            <option value="ROLE_CUSTOMER">Customer Role</option>
            <option value="ROLE_CAGAMAS_USER">Cagamas user Role</option>
            <!-- <option value="RE-OPEN">RE-OPEN</option>
            <option value="RE-SUBMITTED">RE-SUBMITTED</option> -->
            <option value="ROLE_IT_ADMIN">IT Admin Role</option>
          </select>
        </label>
      </div>
      <div class="my-3 d-flex justify-content-between">
        <button class="btn btn-primary btn-custom" type="button" (click)="roleSubmit(); modal.close('Save click')">
          <!-- <i class="fas fa-cog fa-spin"></i> -->
          <span translate>{{'FORGOT.BUTTON2' | translate}}</span>
        </button>
        <button type="button" class="btn btn-outline-dark rounded-0" (click)="modal.close('Save click')">Cancel</button>
      </div>
      <!-- </form> -->
    </div>
  </div>
</ng-template>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
