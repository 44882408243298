import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  httpHeaderOptions: { headers: HttpHeaders };
  applicationheader: { headers: HttpHeaders };
  httpHeaders: any;
  auth_token = localStorage.getItem('bearer');

  constructor(
    private credentialsService: CredentialsService, private _http: HttpClient) {
    this.httpHeaderOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.auth_token + '',
      }),
    };
    this.applicationheader = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.auth_token + '',
      }),
    };
  }

  
  
  getStaffData(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.get<any[]>('/user-management/accounts/', this.httpHeaderOptions)
  }

  createStaffData(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>('/user-management/accounts/', body, this.httpHeaderOptions);
  }

  createRoles(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>( '/user-management/roles/', body, this.httpHeaderOptions);
  }

  getRoles(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.get<any[]>( '/user-management/roles', this.httpHeaderOptions);
  }

  updateRoles(rolesModel: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    };
    return this._http.post<any[]>( '/user-management/updateRoles', rolesModel, this.httpHeaderOptions);
  }

  search = (filterModel: any) => {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token);
    let httpParams = new HttpParams();

    if (filterModel.email != '') {
      httpParams = httpParams.append('email', filterModel.email);
    }
    return this._http.get(`/user-management/search`, { params: httpParams, headers: headers });
  };

  



  validate(): Observable<any> {
    const body = localStorage.getItem('bearer');
    // const httpOptions = {
    //   headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.auth_token),
    // };
    return this._http.post<any[]>('/api/validate-token', body);
  }

}
