import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import Swal from 'sweetalert2';
import { NationalityValidators } from '../../providers/NationalityValidators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  ageerror = false;
  applicForm!: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.createForm();
    this.setUserCategoryValidators();
  }

  ngOnInit() {
    // this.isLoading = true;
    // this.quoteService
    //   .getRandomQuote({ category: 'dev' })
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((quote: string) => {
    //     this.quote = quote;
    //     console.log(this.quote);
    //   });
  }
  private createForm() {
    this.applicForm = this.formBuilder.group({
      joint: ['', Validators.required],
      salutation: [''],
      applicant_name: ['', Validators.required],
      nric_no: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
      res_address: ['', Validators.required],
      postcode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      res_tel: [''],
      hp_tel: ['', Validators.required],
      dob: ['', Validators.required],
      race: ['', Validators.required],
      nationality: ['', Validators.required, NationalityValidators],
      sex: ['', Validators.required],
      marital_status: ['', Validators.required],
      no_dep: [''],
      no_dep1: [''],
      no_dep2: [''],
      no_dep3: [''],
      no_dep4: [''],
      no_dep5: [''],
      present_house: ['', Validators.required],
      occupation: ['', Validators.required],
      name_of_employer: [''],
      address_of_employer: [''],
      postcode_of_employer: [''],
      purpose_of_ssb: ['', Validators.required],
      payout_option: ['', Validators.required],
      usage_of_lumpsum: [''],
      cost_expenses: ['', Validators.required],
      information_source: ['', Validators.required],
    });
  }
  setUserCategoryValidators() {
    const institutionControl = this.applicForm.get('usage_of_lumpsum');
    this.applicForm.get('payout_option')?.valueChanges.subscribe((userCategory) => {
      if (userCategory === 'lumpsum') {
        this.applicForm.controls['usage_of_lumpsum'].setValidators([Validators.required]);
      } else {
        institutionControl?.setValidators(null);
      }
      institutionControl?.updateValueAndValidity();
    });
  }
  apkpk_one() {
    if (this.applicForm.valid) {
      localStorage.setItem('applcation_type', this.applicForm.value.joint);
      this.router.navigate(['/application-part-two']);
    } else {
      // localStorage.setItem('applcation_type', this.applicForm.value.joint);
      // this.router.navigate(['/application-part-two']);
      this.markFormGroupTouched(this.applicForm);
    }
  }
  check_date(e: any) {
    // const convertAge = new Date(e.target.value);
    // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    // var ages = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    var today: any = new Date();
    var birthDate = new Date(e.target.value);
    var ages = Math.floor((today - new Date(birthDate).getTime()) / 3.15576e10);
    console.log('ages>>', ages);
    if (ages < 55) {
      this.ageerror = true;
    } else if (ages > 55) {
      this.ageerror = false;
    } else if (ages == 55) {
      this.ageerror = false;
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
}
