<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/css/all.min.css"
  integrity="sha512-3M00D/rn8n+2ZVXBO9Hib0GKNpkm8MSUU/e2VNthDyBYxKWG+BftNYYcuEjXlyrSO637tidzMBXfE7sQm0INUg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>
<style>
  .ngx-pagination {
    margin-bottom: 2rem;
    padding: 0;
  }
</style>
<section class="main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="heading mt-3">List of Loan Applications</h2>
      </div>
      <div class="col-md-12">
        <form class="filter-area my-3 filter-mobile-100">
          <div class="form-row">
            <div class="col-auto mb-3 mb-lg-0">
              <input
                type="text"
                [(ngModel)]="_filterModel.applicationno"
                name="applicationno"
                class="form-control"
                placeholder="Application No"
              />
            </div>
            <div class="col-auto mb-3 mb-lg-0">
              <input
                type="date"
                [(ngModel)]="_filterModel.date"
                name="date"
                class="form-control"
                placeholder="created date"
              />
            </div>
            <!-- <div class="col-auto">
              <input
                type="text"
                [(ngModel)]="_filterModel.cemail"
                name="cemail"
                class="form-control"
                placeholder="Customer Email"
              />
            </div> -->
            <!-- <div class="col-auto">
              <input
                type="text"
                [(ngModel)]="_filterModel.email"
                name="email"
                class="form-control"
                placeholder="Email"
              />
            </div> -->
            <div class="col-auto mb-3 mb-lg-0">
              <!-- <input
                type="text"
                [(ngModel)]="_filterModel.status"
                name="status"
                class="form-control"
                placeholder="Status"
              /> -->
              <select class="form-control" name="status" [(ngModel)]="_filterModel.status">
                <option value="" disabled>Status</option>
                <option value="DRAFT">DRAFT</option>
                <option value="SUBMITTED">SUBMITTED</option>
                <option value="COMPLETED">COMPLETED</option>
              </select>
            </div>
            <!-- <div class="col-auto">
              <input
                type="number"
                [(ngModel)]="_filterModel.postcode"
                name="postcode"
                class="form-control"
                placeholder="postCode"
              />
            </div> -->
            <div class="col-auto mb-3 mb-lg-0">
              <button (click)="_onSearch()" type="submit" class="btn btn-primary mb-2 btn-filter-custom">Search</button>
            </div>
            <div class="col-auto mb-3 mb-lg-0">
              <button type="button" (click)="reload()" class="btn btn-primary mb-2 btn-filter-custom">Reset</button>
            </div>
            <div class="col-auto mb-3 mb-lg-0">
              <button type="button" (click)="genratecsvmultiple()" class="btn btn-primary mb-2 btn-filter-custom">
                Download CSV
              </button>
            </div>
            <div class="col-auto mb-3 mb-lg-0">
              <button type="button" (click)="genrateexcelmultiple()" class="btn btn-primary mb-2 btn-filter-custom">
                Download Excel
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12">
        <div class="table-responsive css-table">
          <table class="table table-striped custom-table">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" value="" (click)="checkedallapplication($event)" [checked]="allchecked" />
                </th>
                <th>#</th>
                <th>Create Date</th>
                <th>Application ID</th>
                <th>IC Number</th>
                <th>Name</th>

                <!-- <th>Customer Email</th>
                <th>Email</th> -->

                <th>Status</th>
                <!-- <th>Post Code</th> -->
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of UserList | paginate: { itemsPerPage: 10, currentPage: page, totalItems: UserList.length };
                  let i = index
                "
              >
                <td data-title="Select">
                  <input
                    type="checkbox"
                    value="{{ item.applicationId }}"
                    [checked]="applicationlist.includes(item.applicationId)"
                    (click)="checkedapplication(item.applicationId)"
                  />
                </td>
                <td data-title="No">{{ (page - 1) * 10 + i + 1 }}</td>
                <td data-title="Create Date">{{ item.createdDate | date }}</td>
                <td data-title="Application ID">{{ item.applicationId }}</td>
                <td data-title="IC Number">{{ item.nricNumber }}</td>
                <td data-title="Name">{{ item.nameAsPerIc }}</td>

                <!-- <td>{{ item.customerRegisteredEmailId }}</td>
                <td>{{ item.email }}</td> -->

                <td data-title="Status">
                  <span
                    class="bg-warning text-white p-2 py-1 rounded"
                    (click)="open(mymodal, item.applicationId)"
                    style="cursor: pointer; font-size: 14px"
                  >
                    {{ item.orderStatus }}
                  </span>
                  <!-- <span class="bg-warning text-white p-2 py-1 rounded"><small>Pending</small></span> -->
                </td>
                <td data-title="Remarks">{{ item.remarks ? item.remarks : 'NA' }}</td>
                <!-- <td>{{ item.postCode }}</td> -->
                <!-- <td style="text-align: center">
                  <a class="btn-action"><i class="fas fa-eye"></i></a>
                <td>{{ item.postCode }}</td> -->
                <td data-title="Action">
                  <a (click)="_goToView(item)" class="btn-action"><i class="fas fa-eye"></i></a>
                  <!-- <label class="switch mx-2">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label> -->
                  <!-- <a class="btn-action" href="javascript:void(0)" (click)="open(mymodal,item.applicationId)"
                    ><i class="fas fa-edit"></i></a>  -->
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls (pageChange)="page = $event" style="text-align: right"></pagination-controls>
        </div>
        <!-- <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">Previous</a>
            </li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#">Next</a>
            </li>
          </ul>
        </nav> -->
      </div>
    </div>
  </div>
</section>

<ng-template #mymodal let-modal>
  <div class="p-4">
    <div class="card-body">
      <h4 class="card-title text-left mb-4">
        <!-- <i class="far fa-3x fa-user-circle text-muted"></i> -->
        Change Application Status
      </h4>
      <!-- <form (ngSubmit)="remarkssubmit()"  novalidate> -->
      <div class="my-3">
        <label class="d-block mb-3">
          <input
            type="text"
            class="form-control"
            autocomplete="nopefds"
            [placeholder]="'Remarks'"
            [(ngModel)]="remarksModel.remarks"
            required
          />
          <!-- <small
              [hidden]="resetnForm.controls['old_password'].valid || resetnForm.controls['old_password'].untouched"
              class="text-danger"
              translate
            >
              Old Password is required
            </small> -->
        </label>
      </div>
      <div class="my-3">
        <label class="d-block mb-3">
          <select class="form-control" name="status" [(ngModel)]="remarksModel.orderStatus">
            <option value="" disabled>Status</option>
            <option value="DRAFT">DRAFT</option>
            <option value="SUBMITTED">SUBMITTED</option>
            <!-- <option value="RE-OPEN">RE-OPEN</option>
            <option value="RE-SUBMITTED">RE-SUBMITTED</option> -->
            <option value="COMPLETED">COMPLETED</option>
          </select>
        </label>
      </div>
      <div class="my-3 d-flex justify-content-between">
        <button class="btn btn-primary btn-custom" type="button" (click)="remarkssubmit(); modal.close('Save click')">
          <!-- <i class="fas fa-cog fa-spin"></i> -->
          <span translate>{{ 'FORGOT.BUTTON2' | translate }}</span>
        </button>
        <button type="button" class="btn btn-outline-dark rounded-0" (click)="modal.close('Save click')">Cancel</button>
      </div>
      <!-- </form> -->
    </div>
  </div>
</ng-template>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
