import { env } from './.env';

export const environment = {
  production: false,
  version: env['npm_package_version'] + '-dev',
  serverUrl: '/api',
  apiUrl: 'http://34.143.143.146:8080/',
  hostUrl: 'https://digitalssbsit.cagamas.com.my',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
};
