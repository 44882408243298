import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AdminPanelComponent } from './admin-panel.component';


const routes: Routes = [

  Shell.childRoutes([{ path: '', component: AdminPanelComponent, data: { title: marker('Admin Panel') } }]),

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminPanelRoutingModule { }
