<!-- Application Form -->
<section class="application main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <h2 class="heading mt-3">{{ 'APPLICATION.FORM1.TITLE' | translate }}</h2>
          </div>
          <div class="col-md-3 d-flex justify-content-md-end align-items-center">
            <div class="application-logo-text">
              <h3>{{ 'APPLICATION.FORM1.CONFIDENTIAL' | translate }}</h3>
              <h5>{{ 'APPLICATION.FORM1.VERSION' | translate }}</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="application_form col-12">
        <form (ngSubmit)="apkpk_one()" [formGroup]="applicForm" novalidate>
          <div class="application_joint mb-4 mt-3 form-one-line-hld">

            <div class="row">
              <div class="col-md-8">
                <div class="form-group required mb-4">
                  <label for="" class="d-block control-label"
                    >{{ 'APPLICATION.FORM1.FIELD28TEXT' | translate }} :
                  </label>

                  <div class="row">
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="website"
                        class="form-check-input"
                        formControlName="information_source"
                        value="Website"
                      />
                      <label for="website" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD28VALUE1' | translate }} </label>
                      <br />
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="google"
                        class="form-check-input"
                        formControlName="information_source"
                        value="Google"
                      />
                      <label for="google" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD28VALUE2' | translate }}</label>                      
                      <br />
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="socmed"
                        class="form-check-input"
                        formControlName="information_source"
                        value="Social Media (Facebook / Instagram / Tik Tok / Twitter)"
                      />
                      <label for="socmed" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD28VALUE3' | translate }}</label>                      
                      <br />
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="expo"
                        class="form-check-input"
                        formControlName="information_source"
                        value="Expo / Marketing Event / Webinar"
                      />
                      <label for="expo" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD28VALUE4' | translate }}</label>                      
                      <br />
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="family"
                        class="form-check-input"
                        formControlName="information_source"
                        value="Family / Friends"
                      />
                      <label for="family" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD28VALUE5' | translate }}</label>                      
                      <br />
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="tv"
                        class="form-check-input"
                        formControlName="information_source"
                        value="TV / Radio / Newspaper"
                      />
                      <label for="tv" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD28VALUE6' | translate }}</label>
                    </div>

                    <div
                      *ngIf="applicForm.controls['information_source'].errors?.['required'] && !applicForm.controls['information_source'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD28ERROR' | translate }}
                    </div>               
                  </div>             
                </div>
              </div>

              <div class="col-md-4">
                <div><br/></div>
                <div class="row">
                  <!--div class="form-one-line mb-3 mb-md-0"-->
                  <div class="form-one-line">
                    <div class="form-group d-flex">
                      <div class="col-md-6">
                        <p class="mb-2">{{ 'APPLICATION.FORM1.FIELD1TEXT' | translate }}</p>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="form-check form-check-inline">
                            <input type="radio" value="Y" formControlName="joint" class="form-check-input" id="yes" />
                            <label for="yes">{{ 'APPLICATION.FORM1.FIELD1VALUE1' | translate }} </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input type="radio" value="N" formControlName="joint" class="form-check-input" id="no" />
                            <label for="no">{{ 'APPLICATION.FORM1.FIELD1VALUE2' | translate }}</label>
                          </div>
                          <div
                            [hidden]="applicForm.controls['joint'].valid || applicForm.controls['joint'].untouched"
                            class="text-danger px-2 mx-3"
                            translate
                          >
                            {{ 'APPLICATION.FORM1.FIELD1ERROR' | translate }}
                          </div>
                        </div>
                      </div>                      
                    </div>
                  </div>
                  <div><br/></div>
                  <div class="form-one-line">
                    <div class="form-group d-flex">
                      <div class="col-md-6">
                        <p class="mb-2">{{ 'APPLICATION.FORM1.FIELD2TEXT' | translate }}</p>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="form-check form-check-inline">
                            <input
                              type="radio"
                              value="Conventional"
                              formControlName="applicationType"
                              class="form-check-input"
                              id="Conventional"
                            />
                            <label for="Conventional">{{ 'APPLICATION.FORM1.FIELD2VALUE1' | translate }} </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              type="radio"
                              value="Islamic"
                              formControlName="applicationType"
                              class="form-check-input"
                              id="Islamic"
                            />
                            <label for="Islamic">{{ 'APPLICATION.FORM1.FIELD2VALUE2' | translate }}</label>
                          </div>
                          <div
                            [hidden]="
                              applicForm.controls['applicationType'].valid || applicForm.controls['applicationType'].untouched
                            "
                            class="text-danger px-2 mx-3"
                            translate
                          >
                            {{ 'APPLICATION.FORM1.FIELD2ERROR' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- <div class="application_joint mb-4">
            <div class="form-group">
              <div class="form-check form-check-inline px-0">
                <input type="radio" value="Conventional" formControlName="appllicationType" class="form-check-input mx-0" id="Conventional" />
                <label for="Conventional" class="px-2">Conventional </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" value="Islamic" formControlName="appllicationType" class="form-check-input" id="Islamic" />
                <label for="Islamic">Islamic</label>
              </div>
              <div
                [hidden]="applicForm.controls['appllicationType'].valid || applicForm.controls['appllicationType'].untouched"
                class="text-danger px-2 mx-3"
                translate
              >
                This Field is required
              </div>
            </div> -->

          <div class="form-blue-bg mb-5">
            <div class="row">
              <div class="col-md-12">
                <h4 class="para-title">{{ 'APPLICATION.FORM1.HEADER1' | translate }}</h4>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label for="" class="control-label">{{ 'APPLICATION.FORM1.FIELD3TEXT' | translate }} : </label>
                  <select id="salutation" formControlName="salutation" class="form-control">
                    <option value="Mr">{{ 'APPLICATION.FORM1.FIELD3VALUE1' | translate }}</option>
                    <option value="Mdm">{{ 'APPLICATION.FORM1.FIELD3VALUE2' | translate }}</option>
                    <option value="Ms">{{ 'APPLICATION.FORM1.FIELD3VALUE3' | translate }}</option>
                    <option value="Tan Sri">{{ 'APPLICATION.FORM1.FIELD3VALUE4' | translate }}</option>
                    <option value="Dato’">{{ 'APPLICATION.FORM1.FIELD3VALUE5' | translate }}</option>
                    <option value="Other">{{ 'APPLICATION.FORM1.FIELD3VALUE6' | translate }}</option>
                  </select>
                </div>

                <div class="form-group required mb-4">
                  <label for="name" class="control-label">{{ 'APPLICATION.FORM1.FIELD5TEXT' | translate }} : </label>
                  <input
                    type="text"
                    class="form-control"
                    pattern="[A-Za-z\s]+"
                    formControlName="applicant_name"
                    id="name"
                  />
                  <div
                    *ngIf="applicForm.controls['applicant_name'].errors?.['required'] && applicForm.controls['applicant_name'].touched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD5ERROR1' | translate }}
                  </div>

                  <div *ngIf="applicForm.controls['applicant_name'].errors?.['pattern']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD5ERROR2' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="nric_no" class="control-label">{{ 'APPLICATION.FORM1.FIELD7TEXT' | translate }} </label>
                  <input
                    type="text"
                    class="form-control"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 12"
                    formControlName="nric_no"
                    id="nric_no"
                  />
                  <div
                    *ngIf="applicForm.controls['nric_no'].errors?.['required'] && applicForm.controls['nric_no'].touched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD7ERROR1' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['nric_no'].errors?.['minlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD7ERROR2' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['nric_no'].errors?.['maxlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD7ERROR3' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="address" class="control-label">{{ 'APPLICATION.FORM1.FIELD9TEXT' | translate }} : </label>
                  <input type="text" class="form-control" formControlName="res_address" id="address" />
                  <div
                    *ngIf="applicForm.controls['res_address'].errors?.['required'] && applicForm.controls['res_address'].touched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD9ERROR' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="post" class="control-label">{{ 'APPLICATION.FORM1.FIELD11TEXT' | translate }} : </label>
                  <input
                    class="form-control"
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5"
                    onpaste="return false"
                    (change)="post_code_validate()"
                    formControlName="postcode"
                    id="post"
                  />
                  <div
                    *ngIf="applicForm.controls['postcode'].errors?.['required'] && applicForm.controls['postcode'].touched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD11ERROR' | translate }}
                  </div>
                  <div
                    *ngIf="postcodeerror && !applicForm.controls['postcode'].errors?.['required']"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD11ERROR1' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['postcode'].errors?.['minlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD11ERROR2' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['postcode'].errors?.['maxlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD11ERROR3' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="email" class="control-label">{{ 'APPLICATION.FORM1.FIELD13TEXT' | translate }} : </label>
                  <input type="email" class="form-control" formControlName="email" id="email" />
                  <div
                    *ngIf="applicForm.controls['email'].errors?.['required'] && !applicForm.controls['email'].untouched"
                    class="text-danger mt-1"
                  >
                    {{ 'APPLICATION.FORM1.FIELD13ERROR1' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['email'].errors?.['pattern']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD13ERROR2' | translate }}
                  </div>
                </div>
                <div class="form-group required mb-4">
                  <label for="telNo" class="control-label">{{ 'APPLICATION.FORM1.FIELD15TEXT' | translate }} : </label>
                  <input
                    class="form-control"
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                    onpaste="return false"
                    formControlName="hp_tel"
                    id="telNo"
                  />
                  <div
                    *ngIf="applicForm.controls['hp_tel'].errors?.['required'] && applicForm.controls['hp_tel'].touched"
                    class="text-danger mt-1"
                  >
                    {{ 'APPLICATION.FORM1.FIELD15ERROR1' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['hp_tel'].errors?.['minlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD15ERROR2' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['hp_tel'].errors?.['maxlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD15ERROR3' | translate }}
                  </div>
                </div>
                <div class="form-group mb-4">
                  <label for="tel" class="control-label">{{ 'APPLICATION.FORM1.FIELD17TEXT' | translate }} : </label>
                  <input
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                    class="form-control"
                    formControlName="res_tel"
                    id="tel"
                  />
                  <div *ngIf="applicForm.controls['res_tel'].errors?.['minlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD17ERROR1' | translate }}
                  </div>
                  <div *ngIf="applicForm.controls['res_tel'].errors?.['maxlength']" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD17ERROR2' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="dob_box form-group required mb-4">
                  <label for="birthday">{{ 'APPLICATION.FORM1.FIELD4TEXT' | translate }} :</label>
                  <input
                    type="date"
                    class="form-control"
                    id="dob"
                    formControlName="dob"
                    max="9999-12-31"
                    (change)="check_date($event)"
                  />

                  <div
                    *ngIf="applicForm.controls['dob'].errors?.['required'] && applicForm.controls['dob'].touched"
                    class="text-danger mt-1"
                  >
                    {{ 'APPLICATION.FORM1.FIELD4ERROR1' | translate }}
                  </div>
                  <div *ngIf="ageerror" class="text-danger mt-1">
                    {{ 'APPLICATION.FORM1.FIELD4ERROR2' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="Race" class="control-label">{{ 'APPLICATION.FORM1.FIELD6TEXT' | translate }} : </label>
                  <select formControlName="race" id="Race" class="form-control">
                    <option value="Malay">{{ 'APPLICATION.FORM1.FIELD6VALUE1' | translate }}</option>
                    <option value="Chinese">{{ 'APPLICATION.FORM1.FIELD6VALUE2' | translate }}</option>
                    <option value="Indian">{{ 'APPLICATION.FORM1.FIELD6VALUE3' | translate }}</option>
                    <option value="Others - Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE4' | translate }}</option>
                    <option value="Others - Non Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE5' | translate }}</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['race'].errors?.['required'] && applicForm.controls['race'].touched"
                    class="text-danger mt-1"
                  >
                    {{ 'APPLICATION.FORM1.FIELD6ERROR' | translate }}
                  </div>
                </div>

                <div class="form-group required disabled mb-4">
                  <label for="nationality" class="control-label"
                    >{{ 'APPLICATION.FORM1.FIELD8TEXT' | translate }} :
                  </label>
                  <select formControlName="nationality" id="nationality" class="form-control">
                    <option value="Malaysian">{{ 'APPLICATION.FORM1.FIELD8VALUE' | translate }}</option>
                    <!-- <option value="Non-Malaysian">Non-Malaysian</option> -->
                  </select>
                  <div
                    *ngIf="applicForm.controls['nationality'].errors?.['required'] && !applicForm.controls['nationality'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD8ERROR1' | translate }}
                  </div>
                  <div
                    *ngIf="applicForm.controls['nationality'].errors?.['NationalityValidators']"
                    class="text-danger mt-1"
                  >
                    {{ 'APPLICATION.FORM1.FIELD8ERROR2' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="" class="d-block control-label"
                    >{{ 'APPLICATION.FORM1.FIELD10TEXT' | translate }} :
                  </label>
                  <div class="form-check form-check-inline">
                    <input type="radio" id="male" formControlName="sex" class="form-check-input" value="Male" />
                    <label for="male" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD10VALUE1' | translate }} </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" id="female" formControlName="sex" class="form-check-input" value="Female" />
                    <label for="female" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD10VALUE2' | translate }}</label>
                  </div>
                  <div
                    *ngIf="applicForm.controls['sex'].errors?.['required'] && !applicForm.controls['sex'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD10ERROR' | translate }}
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="marital" class="control-label"
                    >{{ 'APPLICATION.FORM1.FIELD12TEXT' | translate }} :
                  </label>
                  <select formControlName="marital_status" id="marital" class="form-control">
                    <option value="Married">{{ 'APPLICATION.FORM1.FIELD12VALUE1' | translate }}</option>
                    <option value="Single">{{ 'APPLICATION.FORM1.FIELD12VALUE2' | translate }}</option>
                    <option value="Widowed">{{ 'APPLICATION.FORM1.FIELD12VALUE3' | translate }}</option>
                    <option value="Divorced">{{ 'APPLICATION.FORM1.FIELD12VALUE4' | translate }}</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['marital_status'].errors?.['required'] && !applicForm.controls['marital_status'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD12ERROR' | translate }}
                  </div>
                </div>

                <div class="form-group mb-4">
                  <label for="no" class="control-label">{{ 'APPLICATION.FORM1.FIELD14TEXT' | translate }} : </label>
                  <input
                    type="number"
                    class="form-control"
                    formControlName="no_dep"
                    min="1"
                    max="5"
                    id="no"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 53"
                    onpaste="return false"
                  />
                </div>

                <div class="form-group mb-4 form-line5">
                  <label for="name">{{ 'APPLICATION.FORM1.FIELD16TEXT' | translate }} : </label>
                  <div class="form-line5-inn">
                    <input
                      type="number"
                      class="form-control"
                      formControlName="no_dep1"
                      min="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false"
                      [readonly]="applicForm.controls['no_dep'].value < 1"
                      id="no5"
                    />
                    <input
                      type="number"
                      class="form-control"
                      formControlName="no_dep2"
                      min="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false"
                      [readonly]="applicForm.controls['no_dep'].value < 2"
                      id="no1"
                    />
                    <input
                      type="number"
                      class="form-control"
                      formControlName="no_dep3"
                      min="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false"
                      [readonly]="applicForm.controls['no_dep'].value < 3"
                      id="no2"
                    />
                    <input
                      type="number"
                      class="form-control"
                      formControlName="no_dep4"
                      min="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false"
                      [readonly]="applicForm.controls['no_dep'].value < 4"
                      id="no3"
                    />
                    <input
                      type="number"
                      class="form-control"
                      formControlName="no_dep5"
                      min="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false"
                      [readonly]="applicForm.controls['no_dep'].value < 5"
                      id="no4"
                    />
                  </div>
                </div>

                <div class="form-group required mb-4">
                  <label for="phouse" class="control-label">{{ 'APPLICATION.FORM1.FIELD18TEXT' | translate }} : </label>
                  <select formControlName="present_house" id="phouse" class="form-control">
                    <option value="Own">{{ 'APPLICATION.FORM1.FIELD18VALUE1' | translate }}</option>
                    <!-- <option value="Rented">Rented</option> -->
                    <option value="Mortgaged">{{ 'APPLICATION.FORM1.FIELD18VALUE2' | translate }}</option>
                    <option value="Family">{{ 'APPLICATION.FORM1.FIELD18VALUE3' | translate }}</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['present_house'].errors?.['required'] && !applicForm.controls['present_house'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD18ERROR' | translate }}
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <hr />
              </div>

              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="occupation" class="control-label"
                    >{{ 'APPLICATION.FORM1.FIELD19TEXT' | translate }} :
                  </label>
                  <input type="text" class="form-control" formControlName="occupation" id="occupation" />
                  <div
                    *ngIf="applicForm.controls['occupation'].errors?.['required'] && !applicForm.controls['occupation'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD19ERROR' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-12">
                <p>*{{ 'APPLICATION.FORM1.FIELD20TEXT' | translate }}</p>
              </div>

              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label for="emp" class="control-label">{{ 'APPLICATION.FORM1.FIELD21TEXT' | translate }} : </label>
                  <input type="text" class="form-control" formControlName="name_of_employer" id="emp" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label for="address" class="control-label"
                    >{{ 'APPLICATION.FORM1.FIELD22TEXT' | translate }} :
                  </label>
                  <input type="text" class="form-control" formControlName="address_of_employer" id="address" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-4">
                  <label for="post">{{ 'APPLICATION.FORM1.FIELD23TEXT' | translate }} : </label>
                  <input
                    type="text"
                    onkeypress="return event.target.value.length < 5"
                    class="form-control"
                    formControlName="postcode_of_employer"
                    id="post"
                  />
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <hr />
              </div>

              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="purpose_of_ssb" class="control-label"
                    >{{ 'APPLICATION.FORM1.FIELD24TEXT' | translate }} :
                  </label>
                  <select formControlName="purpose_of_ssb" class="form-control" id="purpose_of_ssb">
                    <option value="Substitute for Pension">{{ 'APPLICATION.FORM1.FIELD24VALUE1' | translate }}</option>
                    <option value="Pay Medical Expenses">{{ 'APPLICATION.FORM1.FIELD24VALUE2' | translate }}</option>
                    <option value="Maintain Lifestyle">{{ 'APPLICATION.FORM1.FIELD24VALUE3' | translate }}</option>
                  </select>
                  <div
                    *ngIf="applicForm.controls['purpose_of_ssb'].errors?.['required'] && !applicForm.controls['purpose_of_ssb'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD24ERROR' | translate }}
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group required mb-4">
                  <label for="" class="d-block control-label"
                    >{{ 'APPLICATION.FORM1.FIELD25TEXT' | translate }} :
                  </label>

                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="monthly"
                      class="form-check-input"
                      formControlName="payout_option"
                      value="Monthly Payout only"
                    />
                    <label for="monthly" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD25VALUE1' | translate }} </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lumpsum"
                      class="form-check-input"
                      formControlName="payout_option"
                      value="Lumpsum & Monthly Payout"
                    />
                    <label for="lumpsum" class="mt-1 mx-2">{{ 'APPLICATION.FORM1.FIELD25VALUE2' | translate }}</label>
                  </div>

                  <div
                    *ngIf="applicForm.controls['payout_option'].errors?.['required'] && !applicForm.controls['payout_option'].untouched"
                    class="text-danger mt-1"
                    translate
                  >
                    {{ 'APPLICATION.FORM1.FIELD25ERROR' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div
                  class="form-group mb-4"
                  [ngClass]="{
                    required: applicForm.controls['payout_option'].value === 'Lumpsum & Monthly Payout',
                    disabled: applicForm.controls['payout_option'].value !== 'Lumpsum & Monthly Payout'
                  }"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <label for="" class="control-label">{{ 'APPLICATION.FORM1.FIELD26TEXT' | translate }} : </label>
                    </div>

                    <div class="col-md-8">
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="medical"
                          class="form-check-input"
                          formControlName="usage_of_lumpsum"
                          value="Payment for medical expenses"
                        />
                        <label class="mt-1 mx-2" for="medical">{{
                          'APPLICATION.FORM1.FIELD26VALUE1' | translate
                        }}</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="mortgage"
                          class="form-check-input"
                          formControlName="usage_of_lumpsum"
                          value="Settle any outstanding mortgage loan on the property to be charged under the SSB Loan"
                        />
                        <label class="mt-1 mx-2" for="mortgage"
                          >{{ 'APPLICATION.FORM1.FIELD26VALUE2' | translate }}
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="maintenance"
                          class="form-check-input"
                          formControlName="usage_of_lumpsum"
                          value="Refurbishment and maintenance expenses to upkeep the property"
                        />
                        <label class="mt-1 mx-2" for="maintenance"
                          >{{ 'APPLICATION.FORM1.FIELD26VALUE3' | translate }} </label
                        ><br />
                      </div>
                      <div>
                        <small id="passwordHelpBlock" class="form-text text-muted">
                          * {{ 'APPLICATION.FORM1.FIELD26NOTE' | translate }}
                        </small>
                      </div>
                    </div>
                    <div
                      *ngIf="applicForm.controls['usage_of_lumpsum'].errors?.['required'] && !applicForm.controls['usage_of_lumpsum'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD26ERROR' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group required mb-4">
                  <div class="row">
                    <div class="col-md-4">
                      <label for="" class="control-label">{{ 'APPLICATION.FORM1.FIELD27TEXT' | translate }} : </label>
                    </div>

                    <div class="col-md-8">
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="applicant"
                          class="form-check-input"
                          formControlName="cost_expenses"
                          value="To be paid by applicant/borrower"
                        />
                        <label class="mt-1 mx-2" for="applicant">{{
                          'APPLICATION.FORM1.FIELD27VALUE1' | translate
                        }}</label
                        ><br />
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="cagamas"
                          class="form-check-input"
                          formControlName="cost_expenses"
                          value="To be advanced by Cagamas and added to the SSB loan amount"
                        />
                        <label class="mt-1 mx-2" for="cagamas">{{
                          'APPLICATION.FORM1.FIELD27VALUE2' | translate
                        }}</label
                        ><br />
                      </div>
                    </div>
                    <div
                      *ngIf="applicForm.controls['cost_expenses'].errors?.['required'] && !applicForm.controls['cost_expenses'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM1.FIELD27ERROR' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mt-3 justify-content-end d-md-flex text-center">
                  <button
                    class="btn btn-primary btn-custom mobile-wodth-100 mb-2 mb-md-0"
                    type="submit"
                    [disabled]="isLoading"
                  >
                    <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                    <span translate>{{ 'APPLICATION.FORM1.FORM1BUTTON' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
