<!-- Application Form -->
<section class="application main-wrapper pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-9">
            <h2 class="heading mt-3">{{ 'APPLICATION.FORM1.TITLE' | translate }}</h2>
          </div>
          <div class="col-md-3 d-flex justify-content-md-end align-items-center">
            <div class="application-logo-text">
              <h3>{{ 'APPLICATION.FORM1.CONFIDENTIAL' | translate }}</h3>
              <h5>{{ 'APPLICATION.FORM1.VERSION' | translate }}</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="application_form col-12">
        <form [formGroup]="applicForm" novalidate>
          <div>
            <div class="form-blue-bg mb-5">
              <div class="row">
                <div *ngIf="application_type === 'Y' || application_type === 'yes'" class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="para-title">{{ 'APPLICATION.FORM2.HEADER1' | translate }}</h4>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-4">
                        <label for="" class="control-label">{{ 'APPLICATION.FORM1.FIELD3TEXT' | translate }} : </label>
                        <select id="salutation" formControlName="salutation" class="form-control">
                          <option value="Mr">{{ 'APPLICATION.FORM1.FIELD3VALUE1' | translate }}</option>
                          <option value="Mdm">{{ 'APPLICATION.FORM1.FIELD3VALUE2' | translate }}</option>
                          <option value="Ms">{{ 'APPLICATION.FORM1.FIELD3VALUE3' | translate }}</option>
                          <option value="Tan Sri">{{ 'APPLICATION.FORM1.FIELD3VALUE4' | translate }}</option>
                          <option value="Dato’">{{ 'APPLICATION.FORM1.FIELD3VALUE5' | translate }}</option>
                          <option value="Other">{{ 'APPLICATION.FORM1.FIELD3VALUE6' | translate }}</option>
                        </select>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="name" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD5TEXT' | translate }} :
                        </label>
                        <input type="text" formControlName="applicant_name" class="form-control" id="name" />
                        <div
                          [hidden]="
                            applicForm.controls['applicant_name'].valid ||
                            applicForm.controls['applicant_name'].untouched
                          "
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD5ERROR1' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="nric" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD7TEXT' | translate }}
                        </label>
                        <input
                          class="form-control"
                          type="text"
                          formControlName="nric_no"
                          onkeypress="return event.target.value.length < 12"
                          id="nric"
                        />
                        <div
                          *ngIf="applicForm.controls['nric_no'].errors?.['required'] && applicForm.controls['nric_no'].touched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD7ERROR1' | translate }}
                        </div>
                        <div *ngIf="applicForm.controls['nric_no'].errors?.['minlength']" class="text-danger">
                          {{ 'APPLICATION.FORM1.FIELD7ERROR2' | translate }}
                        </div>
                        <div *ngIf="applicForm.controls['nric_no'].errors?.['maxlength']" class="text-danger">
                          {{ 'APPLICATION.FORM1.FIELD7ERROR3' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="address" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD9TEXT' | translate }} :
                        </label>
                        <input type="text" class="form-control" formControlName="res_address" id="address" />
                        <div
                          *ngIf="applicForm.controls['res_address'].errors?.['required'] && applicForm.controls['res_address'].touched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD9ERROR' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="post" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD11TEXT' | translate }} :
                        </label>
                        <input
                          class="form-control"
                          type="text"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5"
                          onpaste="return false"
                          formControlName="postcode"
                          (change)="post_code_validate()"
                          id="post"
                        />
                        <div
                          *ngIf="applicForm.controls['postcode'].errors?.['required'] && applicForm.controls['postcode'].touched"
                          class="text-danger px-2 mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD11ERROR' | translate }}
                        </div>
                        <div
                          *ngIf="postcodeerror && !applicForm.controls['postcode'].errors?.['required']"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD11ERROR1' | translate }}
                        </div>
                        <div *ngIf="applicForm.controls['postcode'].errors?.['minlength']" class="text-danger mt-1">
                          {{ 'APPLICATION.FORM1.FIELD11ERROR2' | translate }}
                        </div>
                        <div *ngIf="applicForm.controls['postcode'].errors?.['maxlength']" class="text-danger mt-1">
                          {{ 'APPLICATION.FORM1.FIELD11ERROR3' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="email" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD13TEXT' | translate }} :
                        </label>
                        <input type="email" class="form-control" formControlName="email" id="email" />
                        <div
                          *ngIf="applicForm.controls['email'].errors?.['required'] && !applicForm.controls['email'].untouched"
                          class="text-danger mt-1"
                        >
                          {{ 'APPLICATION.FORM1.FIELD13ERROR1' | translate }}
                        </div>
                        <div *ngIf="applicForm.controls['email'].errors?.['pattern']" class="text-danger mt-1">
                          {{ 'APPLICATION.FORM1.FIELD13ERROR2' | translate }}
                        </div>
                      </div>
                      <div class="form-group required mb-4">
                        <label for="telNo" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD15TEXT' | translate }} :
                        </label>
                        <input
                          class="form-control"
                          type="text"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                          onpaste="return false"
                          formControlName="hp_tel"
                          id="telNo"
                        />
                        <div
                          *ngIf="applicForm.controls['hp_tel'].errors?.['required'] && applicForm.controls['hp_tel'].touched"
                          class="text-danger mt-1"
                        >
                          {{ 'APPLICATION.FORM1.FIELD15ERROR1' | translate }}
                        </div>
                      </div>

                      <div class="form-group mb-4">
                        <label for="tel">{{ 'APPLICATION.FORM1.FIELD17TEXT' | translate }} : </label>
                        <input
                          type="text"
                          class="form-control"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 11"
                          onpaste="return false"
                          formControlName="res_tel"
                          id="tel"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="dob_box form-group required mb-4">
                        <label for="birthday" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD4TEXT' | translate }} :</label
                        >
                        <input
                          class="form-control"
                          type="date"
                          id="dob"
                          formControlName="dob"
                          max="9999-12-31"
                          (change)="check_date($event)"
                        />

                        <div
                          *ngIf="applicForm.controls['dob'].errors?.['required'] && applicForm.controls['dob'].touched"
                          class="text-danger mt-1"
                        >
                          {{ 'APPLICATION.FORM1.FIELD4ERROR1' | translate }}
                        </div>
                        <div *ngIf="ageerror" class="text-danger mt-1">
                          {{ 'APPLICATION.FORM1.FIELD4ERROR2' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="Race" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD6TEXT' | translate }} :
                        </label>
                        <select formControlName="race" id="Race" class="form-control">
                          <option value="Malay">{{ 'APPLICATION.FORM1.FIELD6VALUE1' | translate }}</option>
                          <option value="Chinese">{{ 'APPLICATION.FORM1.FIELD6VALUE2' | translate }}</option>
                          <option value="Indian">{{ 'APPLICATION.FORM1.FIELD6VALUE3' | translate }}</option>
                          <option value="Others - Bumiputra">{{ 'APPLICATION.FORM1.FIELD6VALUE4' | translate }}</option>
                          <option value="Others - Non Bumiputra">
                            {{ 'APPLICATION.FORM1.FIELD6VALUE5' | translate }}
                          </option>
                        </select>
                        <div
                          *ngIf="applicForm.controls['race'].errors?.['required'] && applicForm.controls['race'].touched"
                          class="text-danger mt-1"
                        >
                          {{ 'APPLICATION.FORM1.FIELD6ERROR' | translate }}
                        </div>
                      </div>

                      <div class="form-group disabled required mb-4">
                        <!-- <div class="form-group  required mb-4"> -->
                        <label for="nationality" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD8TEXT' | translate }} :
                        </label>
                        <select formControlName="nationality" id="nationality" class="form-control">
                          <option value="Malaysian">{{ 'APPLICATION.FORM1.FIELD8VALUE' | translate }}</option>
                          <!-- <option value="Non-Malaysian">Non-Malaysian</option> -->
                        </select>
                        <div
                          *ngIf="applicForm.controls['nationality'].errors?.['required'] && !applicForm.controls['nationality'].untouched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD8ERROR1' | translate }}
                        </div>
                        <div
                          *ngIf="applicForm.controls['nationality'].errors?.['NationalityValidators']"
                          class="text-danger mt-1"
                        >
                          {{ 'APPLICATION.FORM1.FIELD8ERROR2' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="" class="d-block control-label"
                          >{{ 'APPLICATION.FORM1.FIELD10TEXT' | translate }} :
                        </label>
                        <div class="form-check form-check-inline">
                          <input type="radio" id="male" class="form-check-input" formControlName="sex" value="Male" />
                          <label for="male" class="mt-1 mx-2"
                            >{{ 'APPLICATION.FORM1.FIELD10VALUE1' | translate }}
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="radio"
                            id="female"
                            class="form-check-input"
                            formControlName="sex"
                            value="Female"
                          />
                          <label for="female" class="mt-1 mx-2">{{
                            'APPLICATION.FORM1.FIELD10VALUE2' | translate
                          }}</label>
                        </div>

                        <div
                          *ngIf="applicForm.controls['sex'].errors?.['required'] && !applicForm.controls['sex'].untouched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD10ERROR' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="marital" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD12TEXT' | translate }} :
                        </label>
                        <select formControlName="marital_status" id="marital" class="form-control">
                          <option value="Married">{{ 'APPLICATION.FORM1.FIELD12VALUE1' | translate }}</option>
                          <option value="Single">{{ 'APPLICATION.FORM1.FIELD12VALUE2' | translate }}</option>
                          <option value="Widowed">{{ 'APPLICATION.FORM1.FIELD12VALUE3' | translate }}</option>
                          <option value="Divorced">{{ 'APPLICATION.FORM1.FIELD12VALUE4' | translate }}</option>
                        </select>
                        <div
                          *ngIf="applicForm.controls['marital_status'].errors?.['required'] && !applicForm.controls['marital_status'].untouched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD12ERROR' | translate }}
                        </div>
                      </div>

                      <div class="form-group required mb-4">
                        <label for="no" class="control-label"
                          >{{ 'APPLICATION.FORM2.FIELD24TEXT' | translate }} :
                        </label>
                        <select formControlName="relationship" id="marital" class="form-control">
                          <option value="Spouse">{{ 'APPLICATION.FORM2.FIELD24VALUE1' | translate }}</option>
                          <option value="Children">{{ 'APPLICATION.FORM2.FIELD24VALUE2' | translate }}</option>
                          <option value="Parent">{{ 'APPLICATION.FORM2.FIELD24VALUE3' | translate }}</option>
                          <option value="Siblings">{{ 'APPLICATION.FORM2.FIELD24VALUE4' | translate }}</option>
                        </select>
                        <div
                          *ngIf="applicForm.controls['relationship'].errors?.['required'] && !applicForm.controls['relationship'].untouched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM2.FIELD24ERROR' | translate }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mb-3">
                      <hr />
                    </div>

                    <div class="col-md-6">
                      <div class="form-group required mb-4">
                        <label for="occupation" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD19TEXT' | translate }} :
                        </label>
                        <input type="text" formControlName="occupation" id="occupation" class="form-control" />
                        <div
                          *ngIf="applicForm.controls['occupation'].errors?.['required'] && !applicForm.controls['occupation'].untouched"
                          class="text-danger mt-1"
                          translate
                        >
                          {{ 'APPLICATION.FORM1.FIELD19ERROR' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6"></div>

                    <div class="col-md-12">
                      <p>*{{ 'APPLICATION.FORM1.FIELD20TEXT' | translate }}</p>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mb-4">
                        <label for="emp" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD21TEXT' | translate }} :
                        </label>
                        <input type="text" class="form-control" formControlName="name_of_employer" id="emp" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group mb-4">
                        <label for="address" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD22TEXT' | translate }} :
                        </label>
                        <input type="text" class="form-control" formControlName="address_of_employer" id="address" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group mb-4">
                        <label for="post" class="control-label"
                          >{{ 'APPLICATION.FORM1.FIELD23TEXT' | translate }} :
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          autocomplete="nope"
                          onkeypress=" return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5 "
                          formControlName="postcode_of_employer"
                          id="post"
                        />
                      </div>
                    </div>

                    <div class="col-md-12 mb-3">
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pt-4">
                  <h4 class="para-title">{{ 'APPLICATION.FORM2.FIELD1LEVEL' | translate }}</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group required mb-4">
                    <label for="bank" class="control-label">{{ 'APPLICATION.FORM2.FIELD1TEXT' | translate }} : </label>
                    <select formControlName="name_of_bank" id="bank" class="form-control">
                      <option value="Affin Bank Berhad">Affin Bank Berhad</option>
                      <option value="Affin Islamic Bank Berhad">Affin Islamic Bank Berhad</option>
                      <option value="Al Rajhi Banking & Investment Corporation (Malaysia) Berhad">
                        Al Rajhi Banking & Investment Corporation (Malaysia) Berhad
                      </option>
                      <option value="Alliance Bank Malaysia Berhad">Alliance Bank Malaysia Berhad</option>
                      <option value="Alliance Islamic Bank Berhad">Alliance Islamic Bank Berhad</option>
                      <option value="AmBank (M) Berhad">AmBank (M) Berhad</option>
                      <option value="AmBank Islamic Berhad">AmBank Islamic Berhad</option>
                      <option value="Bank Islam Malaysia Berhad">Bank Islam Malaysia Berhad</option>
                      <option value="Bangkok Bank Berhad">Bangkok Bank Berhad</option>
                      <option value="Bank of America Malaysia Berhad">Bank of America Malaysia Berhad</option>
                      <option value="Bank of China (Malaysia) Berhad">Bank of China (Malaysia) Berhad</option>
                      <option value="Bank Muamalat Malaysia Berhad">Bank Muamalat Malaysia Berhad</option>
                      <option value="BNP Paribas Malaysia Berhad">BNP Paribas Malaysia Berhad</option>
                      <option value="China Construction Bank (Malaysia) Berhad">
                        China Construction Bank (Malaysia) Berhad
                      </option>
                      <option value="Citibank Berhad">Citibank Berhad</option>
                      <option value="Deutsche Bank (Malaysia) Berhad">Deutsche Bank (Malaysia) Berhad</option>
                      <option value="Hong Leong Bank Berhad">Hong Leong Bank Berhad</option>
                      <option value="Hong Leong Islamic Bank Berhad">Hong Leong Islamic Bank Berhad</option>
                      <option value="HSBC Amanah Malaysia Berhad">HSBC Amanah Malaysia Berhad</option>
                      <option value="HSBC Bank Malaysia Berhad">HSBC Bank Malaysia Berhad</option>
                      <option value="India International Bank (Malaysia) Berhad">
                        India International Bank (Malaysia) Berhad
                      </option>
                      <option value="Industrial and Commercial Bank of China (Malaysia) Berhad">
                        Industrial and Commercial Bank of China (Malaysia) Berhad
                      </option>
                      <option value="J.P. Morgan Chase Bank Berhad">J.P. Morgan Chase Bank Berhad</option>
                      <option value="Kuwait Finance House (Malaysia) Berhad">
                        Kuwait Finance House (Malaysia) Berhad
                      </option>
                      <option value="Maybank Islamic Berhad">Maybank Islamic Berhad</option>
                      <option value="Malayan Banking Berhad">Malayan Banking Berhad</option>
                      <option value="MBSB Bank Berhad">MBSB Bank Berhad</option>
                      <option value="Mizuho Bank (Malaysia) Berhad">Mizuho Bank (Malaysia) Berhad</option>
                      <option value="OCBC Bank (Malaysia) Berhad">OCBC Bank (Malaysia) Berhad</option>
                      <option value="OCBC Al-Amin Bank Berhad">OCBC Al-Amin Bank Berhad</option>
                      <option value="Public Bank Berhad">Public Bank Berhad</option>
                      <option value="Public Islamic Bank Berhad">Public Islamic Bank Berhad</option>
                      <option value="RHB Bank Berhad">RHB Bank Berhad</option>
                      <option value="RHB Islamic Bank Berhad">RHB Islamic Bank Berhad</option>
                      <option value="Sumitomo Mitsui Banking Corporation Malaysia Berhad">
                        Sumitomo Mitsui Banking Corporation Malaysia Berhad
                      </option>
                      <option value="Standard Chartered Saadiq Berhad">Standard Chartered Saadiq Berhad</option>
                      <option value="The Bank of Nova Scotia Berhad">The Bank of Nova Scotia Berhad</option>
                      <option value="United Overseas Bank (Malaysia) Bhd">United Overseas Bank (Malaysia) Bhd</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['name_of_bank'].errors?.['required'] && !applicForm.controls['name_of_bank'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD1ERROR' | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group required mb-4">
                    <label for="" class="control-label">{{ 'APPLICATION.FORM2.FIELD2TEXT' | translate }} : </label>
                    <select formControlName="account_type" id="account" class="form-control">
                      <option value=""></option>
                      <option *ngIf="application_type !== 'Y'" value="Savings">
                        {{ 'APPLICATION.FORM2.FIELD2LEBEL1' | translate }}
                      </option>
                      <option *ngIf="application_type !== 'Y'" value="Current">
                        {{ 'APPLICATION.FORM2.FIELD2LEBEL2' | translate }}
                      </option>
                      <option *ngIf="application_type === 'Y'" value="Joint Savings">
                        {{ 'APPLICATION.FORM2.FIELD2LEBEL3' | translate }}
                      </option>
                      <option *ngIf="application_type === 'Y'" value="Joint Current">
                        {{ 'APPLICATION.FORM2.FIELD2LEBEL4' | translate }}
                      </option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['account_type'].errors?.['required'] && !applicForm.controls['account_type'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD2ERROR' | translate }}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group required mb-4">
                    <label for="ac_no" class="control-label">{{ 'APPLICATION.FORM2.FIELD3TEXT' | translate }} : </label>
                    <input type="text" class="form-control" formControlName="account_number" id="ac_no" />
                    <div
                      *ngIf="applicForm.controls['account_number'].errors?.['required'] && !applicForm.controls['account_number'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD3ERROR' | translate }}
                    </div>
                  </div>
                </div>

                <!---->
                <div class="col-md-6">
                  <div class="form-group mb-4">
                    <!--label for="" class="d-block control-label"
                    >{{ 'APPLICATION.FORM2.FIELD25TEXT' | translate }} :
                    </label-->
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="atyes"
                        class="form-check-input"
                        formControlName="account_type_con_isl"
                        value="Conventional"
                      />
                      <label for="atyes" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD25VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="atno"
                        class="form-check-input"
                        formControlName="account_type_con_isl"
                        value="Islamic"
                      />
                      <label for="atno" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD25VALUE2' | translate }}</label>
                    </div>
                    <div
                      *ngIf="applicForm.controls['account_type_con_isl'].errors?.['required'] && !applicForm.controls['account_type_con_isl'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD25ERROR' | translate }}
                    </div>
                  </div>
                </div>
                <!---->

                <div class="col-md-12 pt-4">
                  <h4 class="para-title">{{ 'APPLICATION.FORM2.FIELD4LEVEL' | translate }}</h4>
                </div>

                <div class="col-md-6">
                  <div class="form-group required mb-4">
                    <label for="property_type" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD5TEXT' | translate }} :
                    </label>
                    <select id="property_type" formControlName="property_type" class="form-control">
                      <option value="Terrace">{{ 'APPLICATION.FORM2.FIELD5VALUE1' | translate }}</option>
                      <option value="High-Rise">{{ 'APPLICATION.FORM2.FIELD5VALUE2' | translate }}</option>
                      <option value="Semi-Detach">{{ 'APPLICATION.FORM2.FIELD5VALUE3' | translate }}</option>
                      <option value="Detach">{{ 'APPLICATION.FORM2.FIELD5VALUE4' | translate }}</option>
                      <option value="Others">{{ 'APPLICATION.FORM2.FIELD5VALUE5' | translate }}</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['property_type'].errors?.['required'] && !applicForm.controls['property_type'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD5ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="property_address" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD7TEXT' | translate }} :
                    </label>
                    <input type="text" class="form-control" formControlName="property_address" id="property_address" />
                    <div
                      *ngIf="applicForm.controls['property_address'].errors?.['required'] && !applicForm.controls['property_address'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD7ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="property_postcode" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD9TEXT' | translate }} :
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && event.target.value.length < 5"
                      onpaste="return false"
                      (change)="post_code_validate2()"
                      formControlName="property_postcode"
                      id="post"
                    />
                    <div
                      *ngIf="propertypostcodeerror && !applicForm.controls['property_postcode'].errors?.['required']"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD9ERROR1' | translate }}
                    </div>
                    <div
                      *ngIf="applicForm.controls['property_postcode'].errors?.['required'] && !applicForm.controls['property_postcode'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD9ERROR' | translate }}
                    </div>
                    <div
                      *ngIf="applicForm.controls['property_postcode'].errors?.['minlength']"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM2.FIELD9ERROR2' | translate }}
                    </div>
                    <div
                      *ngIf="applicForm.controls['property_postcode'].errors?.['maxlength']"
                      class="text-danger mt-1"
                    >
                      {{ 'APPLICATION.FORM2.FIELD9ERROR3' | translate }}
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name" class="control-label">{{ 'APPLICATION.FORM2.FIELD11TEXT' | translate }}: </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        class="form-control"
                        type="text"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        (change)="numberformat('market_value')"
                        formControlName="market_value"
                        id="market_value"
                      />
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="valuation" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD13TEXT' | translate }} :</label
                    >
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="valuation_date"
                      id="valuation"
                    />
                  </div>

                  <div class="form-group required mb-4">
                    <label for="expectation_market_value" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD15TEXT' | translate }} :
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="expectation_market_value"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        (change)="numberformat('expectation_market_value')"
                        id="expectation_market_value"
                      />
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="name" class="control-label">{{ 'APPLICATION.FORM2.FIELD17TEXT' | translate }} : </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        type="text"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        (change)="numberformat('sale_purchase_price')"
                        formControlName="sale_purchase_price"
                        id="value"
                      />
                    </div>
                  </div>

                  <div
                    class="form-group mb-4"
                    [ngClass]="{
                      required: applicForm.controls['sale_purchase_price'].value,
                      disabled: !applicForm.controls['sale_purchase_price'].value
                    }"
                  >
                    <label for="sale_purchase_date" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD19TEXT' | translate }} :</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      max="9999-12-31"
                      formControlName="sale_purchase_date"
                      id="sale_purchase_date"
                    />
                    <div
                      *ngIf="applicForm.controls['sale_purchase_date'].errors?.['required'] && !applicForm.controls['sale_purchase_date'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD19ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="tenure" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD21TEXT' | translate }} :
                    </label>
                    <select formControlName="property_tenure" id="tenure" class="form-control">
                      <option value="Freehold">{{ 'APPLICATION.FORM2.FIELD21LEBEL1' | translate }}</option>
                      <option value="Leasehold">{{ 'APPLICATION.FORM2.FIELD21LEBEL2' | translate }}</option>
                    </select>
                    <div
                      *ngIf="applicForm.controls['property_tenure'].errors?.['required'] && !applicForm.controls['property_tenure'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD21ERROR' | translate }}
                    </div>
                  </div>

                  <div
                    class="form-group mb-4"
                    [ngClass]="{
                      required: applicForm.controls['property_tenure'].value === 'Leasehold',
                      disabled: applicForm.controls['property_tenure'].value !== 'Leasehold'
                    }"
                  >
                    <label for="date" class="control-label"> {{ 'APPLICATION.FORM2.FIELD23TEXT' | translate }} :</label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="lease_expiry_date"
                      id="valuation"
                    />
                    <div
                      *ngIf="applicForm.controls['lease_expiry_date'].errors?.['required'] && !applicForm.controls['lease_expiry_date'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD23ERROR' | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group required mb-4">
                    <label for="build_area" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD6TEXT' | translate }} :
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="build_up_area"
                      id="build_area"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    />
                    <div
                      *ngIf="applicForm.controls['build_up_area'].errors?.['required'] && !applicForm.controls['build_up_area'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD6ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="land_area" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD8TEXT' | translate }} :
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="land_area"
                      id="land_area"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    />
                    <div
                      *ngIf="applicForm.controls['land_area'].errors?.['required'] && !applicForm.controls['land_area'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD8ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label class="d-block control-label" for=""
                      >{{ 'APPLICATION.FORM2.FIELD10TEXT' | translate }} :
                    </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="yes"
                        class="form-check-input"
                        formControlName="property_encumbered"
                        value="Yes"
                      />
                      <label for="yes" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD10VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="no"
                        class="form-check-input"
                        formControlName="property_encumbered"
                        value="No"
                      />
                      <label for="no" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD10VALUE2' | translate }}</label>
                    </div>
                    <div
                      *ngIf="applicForm.controls['property_encumbered'].errors?.['required'] && !applicForm.controls['property_encumbered'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD10ERROR' | translate }}
                    </div>
                  </div>

                  <div
                    class="form-group mb-4"
                    [ngClass]="{
                      required: applicForm.controls['property_encumbered'].value === 'Yes',
                      disabled: applicForm.controls['property_encumbered'].value !== 'Yes'
                    }"
                  >
                    <label for="property_bank" class="control-label">
                      {{ 'APPLICATION.FORM2.FIELD12TEXT' | translate }} :
                    </label>
                    <select formControlName="property_bank_name" id="property_bank" class="form-control">
                      <option value="Affin Bank Berhad">Affin Bank Berhad</option>
                      <option value="Affin Islamic Bank Berhad">Affin Islamic Bank Berhad</option>
                      <option value="Al Rajhi Banking & Investment Corporation (Malaysia) Berhad">
                        Al Rajhi Banking & Investment Corporation (Malaysia) Berhad
                      </option>
                      <option value="Alliance Bank Malaysia Berhad">Alliance Bank Malaysia Berhad</option>
                      <option value="Alliance Islamic Bank Berhad">Alliance Islamic Bank Berhad</option>
                      <option value="AmBank (M) Berhad">AmBank (M) Berhad</option>
                      <option value="AmBank Islamic Berhad">AmBank Islamic Berhad</option>
                      <option value="Bank Islam Malaysia Berhad">Bank Islam Malaysia Berhad</option>
                      <option value="Bangkok Bank Berhad">Bangkok Bank Berhad</option>
                      <option value="Bank of America Malaysia Berhad">Bank of America Malaysia Berhad</option>
                      <option value="Bank of China (Malaysia) Berhad">Bank of China (Malaysia) Berhad</option>
                      <option value="Bank Muamalat Malaysia Berhad">Bank Muamalat Malaysia Berhad</option>
                      <option value="BNP Paribas Malaysia Berhad">BNP Paribas Malaysia Berhad</option>
                      <option value="China Construction Bank (Malaysia) Berhad">
                        China Construction Bank (Malaysia) Berhad
                      </option>
                      <option value="Citibank Berhad">Citibank Berhad</option>
                      <option value="Deutsche Bank (Malaysia) Berhad">Deutsche Bank (Malaysia) Berhad</option>
                      <option value="Hong Leong Bank Berhad">Hong Leong Bank Berhad</option>
                      <option value="Hong Leong Islamic Bank Berhad">Hong Leong Islamic Bank Berhad</option>
                      <option value="HSBC Amanah Malaysia Berhad">HSBC Amanah Malaysia Berhad</option>
                      <option value="HSBC Bank Malaysia Berhad">HSBC Bank Malaysia Berhad</option>
                      <option value="India International Bank (Malaysia) Berhad">
                        India International Bank (Malaysia) Berhad
                      </option>
                      <option value="Industrial and Commercial Bank of China (Malaysia) Berhad">
                        Industrial and Commercial Bank of China (Malaysia) Berhad
                      </option>
                      <option value="J.P. Morgan Chase Bank Berhad">J.P. Morgan Chase Bank Berhad</option>
                      <option value="Kuwait Finance House (Malaysia) Berhad">
                        Kuwait Finance House (Malaysia) Berhad
                      </option>
                      <option value="Maybank Islamic Berhad">Maybank Islamic Berhad</option>
                      <option value="Malayan Banking Berhad">Malayan Banking Berhad</option>
                      <option value="MBSB Bank Berhad">MBSB Bank Berhad</option>
                      <option value="Mizuho Bank (Malaysia) Berhad">Mizuho Bank (Malaysia) Berhad</option>
                      <option value="OCBC Bank (Malaysia) Berhad">OCBC Bank (Malaysia) Berhad</option>
                      <option value="OCBC Al-Amin Bank Berhad">OCBC Al-Amin Bank Berhad</option>
                      <option value="Public Bank Berhad">Public Bank Berhad</option>
                      <option value="Public Islamic Bank Berhad">Public Islamic Bank Berhad</option>
                      <option value="RHB Bank Berhad">RHB Bank Berhad</option>
                      <option value="RHB Islamic Bank Berhad">RHB Islamic Bank Berhad</option>
                      <option value="Sumitomo Mitsui Banking Corporation Malaysia Berhad">
                        Sumitomo Mitsui Banking Corporation Malaysia Berhad
                      </option>
                      <option value="Standard Chartered Saadiq Berhad">Standard Chartered Saadiq Berhad</option>
                      <option value="The Bank of Nova Scotia Berhad">The Bank of Nova Scotia Berhad</option>
                      <option value="United Overseas Bank (Malaysia) Bhd">United Overseas Bank (Malaysia) Bhd</option>
                    </select>

                    <div
                      *ngIf="applicForm.controls['property_bank_name'].errors?.['required'] && !applicForm.controls['property_bank_name'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD12ERROR' | translate }}
                    </div>
                  </div>

                  <div
                    class="form-group mb-4"
                    [ngClass]="{
                      required: applicForm.controls['property_encumbered'].value === 'Yes',
                      disabled: applicForm.controls['property_encumbered'].value != 'Yes'
                    }"
                  >
                    <label for="name" class="control-label">{{ 'APPLICATION.FORM2.FIELD14TEXT' | translate }} : </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">RM</div>
                      </div>
                      <input
                        class="form-control"
                        type="text"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        (change)="numberformat('outstanding_balns')"
                        formControlName="outstanding_balns"
                        id="address"
                      />
                    </div>

                    <div
                      *ngIf="applicForm.controls['outstanding_balns'].errors?.['required'] && !applicForm.controls['outstanding_balns'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD14ERROR' | translate }}
                    </div>
                  </div>

                  <div class="form-group required mb-4">
                    <label for="" class="d-block control-label"
                      >{{ 'APPLICATION.FORM2.FIELD16TEXT' | translate }} :
                    </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="fyes"
                        class="form-check-input"
                        formControlName="fire_home_insurence"
                        value="In force"
                      />
                      <label for="fyes" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD16VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="fno"
                        class="form-check-input"
                        formControlName="fire_home_insurence"
                        value="Not Available"
                      />
                      <label for="fno" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD16VALUE2' | translate }}</label>
                    </div>
                    <div
                      *ngIf="applicForm.controls['fire_home_insurence'].errors?.['required'] && !applicForm.controls['fire_home_insurence'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD16ERROR' | translate }}
                    </div>
                  </div>

                  <div
                    class="form-group mb-4"
                    [ngClass]="{
                      required: applicForm.controls['fire_home_insurence'].value === 'In force',
                      disabled: applicForm.controls['fire_home_insurence'].value === 'Not Available'
                    }"
                    *ngIf="applicForm.controls['fire_home_insurence'].value === 'In force'"
                  >
                    <label for="insurer_name" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD18TEXT' | translate }} :
                    </label>
                    <input class="form-control" type="text" formControlName="insurer_name" id="insurer_name" />
                    <div
                      *ngIf="applicForm.controls['insurer_name'].errors?.['required'] && !applicForm.controls['insurer_name'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD18ERROR' | translate }}
                    </div>
                  </div>
                  <div
                    class="form-group mb-4"
                    [ngClass]="{
                      required: applicForm.controls['fire_home_insurence'].value === 'In force',
                      disabled: applicForm.controls['fire_home_insurence'].value === 'Not Available'
                    }"
                    *ngIf="applicForm.controls['fire_home_insurence'].value === 'In force'"
                  >
                    <!-- <div class="form-group mb-4" [ngClass]="{'required': applicForm.controls['fire_home_insurence'].value === 'In force'}"> -->
                    <label for="period_validity" class="control-label"
                      >{{ 'APPLICATION.FORM2.FIELD20TEXT' | translate }}:
                    </label>
                    <input
                      class="form-control"
                      max="9999-12-31"
                      type="date"
                      max="9999-12-31"
                      formControlName="period_validity"
                      id="period_validity"
                    />
                    <div
                      *ngIf="applicForm.controls['period_validity'].errors?.['required'] && !applicForm.controls['period_validity'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD20ERROR' | translate }}
                    </div>
                  </div>

                  <!---->
                  <div
                    class="form-group required mb-4"
                    [ngClass]="{
                      required: applicForm.controls['fire_home_insurence'].value === 'Not Available',
                      disabled: applicForm.controls['fire_home_insurence'].value === 'In force'
                    }"
                    *ngIf="applicForm.controls['fire_home_insurence'].value === 'Not Available'"
                  >
                    <label for="" class="d-block control-label"
                      >{{ 'APPLICATION.FORM2.FIELD26TEXT' | translate }} :
                    </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="riyes"
                        class="form-check-input"
                        formControlName="renewal_insurance_caga"
                        value="Yes"
                      />
                      <label for="riyes" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD26VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="rino"
                        class="form-check-input"
                        formControlName="renewal_insurance_caga"
                        value="No"
                      />
                      <label for="rino" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD26VALUE2' | translate }}</label>
                    </div>
                    <div
                      *ngIf="applicForm.controls['renewal_insurance_caga'].errors?.['required'] && !applicForm.controls['renewal_insurance_caga'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD26ERROR' | translate }}
                    </div>
                  </div>
                  <!---->

                  <div class="form-group required mb-4">
                    <label for="" class="d-block control-label"
                      >{{ 'APPLICATION.FORM2.FIELD22TEXT' | translate }} :
                    </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="yes3"
                        class="form-check-input"
                        formControlName="renewal_insurence"
                        value="Self-renewal"
                      />
                      <label for="yes3" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD22VALUE1' | translate }} </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        id="no3"
                        class="form-check-input"
                        formControlName="renewal_insurence"
                        value="To be renewed by Cagamas where premium will be deducted from Monthly Payout"
                      />
                      <label for="no3" class="mt-1 mx-2">{{ 'APPLICATION.FORM2.FIELD22VALUE2' | translate }}</label>
                    </div>
                    <div
                      *ngIf="applicForm.controls['renewal_insurence'].errors?.['required'] && !applicForm.controls['renewal_insurence'].untouched"
                      class="text-danger mt-1"
                      translate
                    >
                      {{ 'APPLICATION.FORM2.FIELD22ERROR' | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 justify-content-between d-md-flex text-center">
                <button
                  class="btn btn-primary btn-back desktop-show mobile-wodth-100 mb-2 mb-md-0"
                  type="submit"
                  (click)="prevStep()"
                  [disabled]="isLoading"
                >
                  <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                  <span translate>{{ 'APPLICATION.FORM2.BUTTON1TEXT' | translate }}</span>
                </button>

                <button
                  class="btn btn-primary btn-custom mobile-wodth-100 mb-2 mb-md-0"
                  type="submit"
                  (click)="submitForm()"
                  [disabled]="isLoading"
                >
                  <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                  <span translate>{{ 'APPLICATION.FORM2.BUTTON2TEXT' | translate }}</span>
                </button>
                <button
                  class="btn btn-primary btn-back mobile-show mobile-wodth-100 mb-2 mb-md-0"
                  type="submit"
                  (click)="prevStep()"
                  [disabled]="isLoading"
                >
                  <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                  <span translate>{{ 'APPLICATION.FORM2.BUTTON1TEXT' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Footer Start-->
<footer class="site-footer">
  <div class="site-footer__bottom">
    <div class="container">
      <section class="region region-footer-fifth">
        <div id="block-footercopyright" class="block-copyright block block-block-content">
          <div class="content">
            <div
              class="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item"
            >
              <div class="left-col">
                <p>{{ 'APPLICATION.FORM1.FORM1COPYRIGHT' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</footer>
<!-- Footer End-->
